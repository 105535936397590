import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SetsidComponent } from "./setsid/setsid.component";
import { MqloginComponent } from "./mqlogin/mqlogin.component";
import { AuthService } from "./AuthGuard/auth.service";
import { AuthGuard } from "./AuthGuard/auth.guard";
import { AppComponent } from "./app.component";
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { HomeLayoutComponent } from './layout/home-layout/home-layout.component';

export const approutes: Routes = [
  { path: "", component: MqloginComponent },
  { path: "setSID/:uid/:aid", component: SetsidComponent },
  { path: "setSID/:qpm/:uid/:aid", component: SetsidComponent },
  { path: "clrSID", component: SetsidComponent },
  {
    path: '', component: LoginLayoutComponent, children: [
      { path: "", component: MqloginComponent },
    ]
  },
  {
    path: '', component: HomeLayoutComponent, children: [
      {
        path: "RCM",
        loadChildren: () =>
          import("./ModuleRCM/RCM.module").then(m => m.QdashboardRCMModule)
      },
      {
        path: "value",
        loadChildren: () =>
          import("./Modulevalue/qdashboard-value.module").then(m => m.QdashboardValueModule)
      },
      {
        path: "miu",
        loadChildren: () =>
          import("./ModuleMIU/qdashboard-miu.module").then(m => m.QdashboardMIUModule)

      },
      {
        path: "financialmetrics",
        loadChildren: () =>
          import("./ModelFinancialMetrics/financial-metrics.module").then(m => m.FinancialMetricsModule)
      },
      {
        path: "qdashboards",
        loadChildren: () =>
          import("./Modelqdashboards/qdashboards.module").then(m => m.QdashboardsModule)
      },
      {
        path: "mldashboards",
        loadChildren: () =>
          import("./module-ml/module-ml.module").then(m => m.ModuleMLModule)
      },
      {
        path: "trend",
        loadChildren: () =>
          import("./ModuleTrend/trend.module").then(m => m.trendModule)
      },
    ]
  },



];

export const routing = RouterModule.forRoot(approutes, { useHash: true });
