import { Injectable } from "@angular/core";
import { Permission } from "src/app/CallServices/Permission";
import { TokenEncryptionService } from "src/app/CallServices/token-encryption.service";
@Injectable()
export class dashboardList {
    dashboardItemList = [];
    constructor(public permission: Permission, private tokenEncryptDecrypt: TokenEncryptionService,) {
        this.dashboardlist();
    }

    dashboardlist() {
        let Pathpermissions = this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("Permissions"));
        const retrievedObject = JSON.parse(Pathpermissions);
        let alldashboardlist = [
            { label: 'RCM Key Metrics', route: '/financialmetrics/keyMetrics', permission: 'keyMetrics', id: 'financial' },
            { label: 'Provider Productivity', route: '/financialmetrics/ProviderProductivity', permission: 'ProviderProductivity', id: 'financial' },
            { label: 'Financial Productivity', route: '/financialmetrics/Financialproductivity', permission: 'Financialproductivity', id: 'financial' },
            { label: 'RCM Charges Analysis', route: '/financialmetrics/ChargesAnalysis', permission: 'ChargesAnalysis', id: 'financial' },
            { label: 'RCM Payment Analysis', route: '/financialmetrics/paymentAnalysis', permission: 'paymentAnalysis', id: 'financial' },
            { label: 'Refund Analysis', route: '/financialmetrics/RefundAnalysis', permission: 'RefundAnalysis', id: 'financial' },
            { label: 'Daily Tracker', route: '/financialmetrics/DailyTracker', permission: 'DailyTracker', id: 'financial' },
            { label: 'Overall AR', route: '/financialmetrics/OverallARAndbreakup', permission: 'OverallARAndbreakup', id: 'financial' },
            { label: 'AR Aging by Payer Type', route: '/financialmetrics/InsuranceDebitARAging', permission: 'InsuranceDebitARAging', id: 'financial' },
            { label: 'Provider YTD', route: '/financialmetrics/ProviderYTD', permission: 'ProviderYTD', id: 'financial' },
            { label: 'Provider Charges', route: '/financialmetrics/ProviderCharges', permission: 'ProviderCharges', id: 'financial' },
            { label: 'Provider Payments', route: '/financialmetrics/ProviderPayments', permission: 'ProviderPayments', id: 'financial' },

            { label: 'Account Receivable', route: '/RCM/Acountrecivable', permission: 'Acountrecivable', id: 'rcm' },
            { label: 'Denial History', route: '/RCM/DenialsHistory', permission: 'DenialsHistory', id: 'rcm' },
            { label: 'Denial Management', route: '/RCM/DenialManagement', permission: 'DenialManagement', id: 'rcm' },
            { label: 'Missing Charges', route: '/RCM/MissingCharges', permission: 'MissingCharges', id: 'rcm' },
            { label: 'Monthly Charges', route: '/RCM/MonthlyCharges', permission: 'MonthlyCharges', id: 'rcm' },
            { label: 'Monthly Close', route: '/RCM/MonthlyClose', permission: 'MonthlyClose', id: 'rcm' },
            { label: 'Missed Opportunities', route: '/RCM/MissedOpportunities', permission: 'MissedOpportunities', id: 'rcm' },
            { label: 'MTD YTD Report', route: '/RCM/MtdYtdReport', permission: 'MtdYtdReport', id: 'rcm' },
            { label: 'Daily Charges', route: '/RCM/DailyCharges', permission: 'DailyCharges', id: 'rcm' },
            { label: 'Daily Close', route: '/RCM/DailyClose', permission: 'DailyClose', id: 'rcm' },
            { label: 'Monthly Payment', route: '/RCM/MonthlyPayment', permission: 'MonthlyPayment', id: 'rcm' },
            { label: 'RCM Productivity', route: '/RCM/allrcmProductivity', permission: 'allrcmProductivity', id: 'rcm' },
            { label: 'Ins Bal AR', route: '/RCM/dellclaimexport', permission: 'dellclaimexport', id: 'rcm' },
            { label: 'ERA Status Dashboard', route: '/RCM/erapaymentposting', permission: 'erapaymentposting', id: 'rcm' },
            { label: 'Insurance Pending checks', route: '/RCM/Insurancependingchecks', permission: 'Insurancependingchecks', id: 'rcm' },
            { label: 'On Hold Claims', route: '/RCM/QPMholddashboard', permission: 'QPMholddashboard', id: 'rcm' },
            { label: 'Timely Filing', route: '/RCM/TimelyFiling', permission: 'TimelyFiling', id: 'rcm' },
            { label: 'CPT Analysis', route: '/RCM/CPTAnalysis', permission: 'CPTAnalysis', id: 'rcm' },
            { label: 'Financial Cash Flow', route: '/RCM/RCMWaterfall', permission: 'RCMWaterfall', id: 'rcm' },
            { label: 'Revenue Cycle', route: '/RCM/RevenueCycle', permission: 'RevenueCycle', id: 'rcm' },
            { label: 'Expected Collection', route: '/RCM/ExpectedCollection', permission: 'ExpectedCollection', id: 'rcm' },
            { label: 'CPT-Expected Collection', route: '/RCM/CPTExpectedCollection', permission: 'CPTExpectedCollection', id: 'rcm' },
            { label: 'Panel Payer Distribution', route: '/RCM/PanelPayerDistribution', permission: 'PanelPayerDistribution', id: 'rcm' },
            { label: 'Panel Distribution', route: '/RCM/paneldistribution', permission: 'paneldistribution', id: 'rcm' },
            { label: 'Location Wise Financial Payment', route: '/RCM/LocationFinancialPayment', permission: 'LocationFinancialPayment', id: 'rcm' },
            { label: 'RCM Day Wise Payer Payment', route: '/RCM/RCMDayWisePayerPayment', permission: 'RCMDayWisePayerPayment', id: 'rcm' },
            { label: 'Claim Not Worked Upon', route: '/RCM/ClaimNotWorkedUpon', permission: 'ClaimNotWorkedUpon', id: 'rcm' },
            { label: 'Patient Payment', route: '/RCM/PatientPayment', permission: 'PatientPayment', id: 'rcm' },
            { label: 'Practice Compensation', route: '/RCM/PracticeCompensation', permission: 'PracticeCompensation', id: 'rcm' },
            { label: 'Provider Compensation', route: '/RCM/ProviderCompensation', permission: 'ProviderCompensation', id: 'rcm' },
            { label: 'Provider Comp by CPT, Facility', route: '/RCM/ProviderCompensationbyPOS', permission: 'ProviderCompensationbyPOS', id: 'rcm' },
            { label: 'Account Receivable Dash', route: '/RCM/AcountrecivableinDash', permission: 'AcountrecivableinDash', id: 'rcm' },
            { label: 'Daily Charge Details', route: '/RCM/DailyChargeDetails', permission: 'DailyChargeDetails', id: 'rcm' },
            { label: 'Charge Lag And Batch Lag', route: '/RCM/chargebatchLag', permission: 'chargebatchLag', id: 'rcm' },
            { label: 'Charge Lag And Exam Finished Lag', route: '/RCM/ExamFinishLag', permission: 'ExamFinishLag', id: 'rcm' },
            { label: 'Metrics', route: '/RCM/Metric', permission: 'Metric', id: 'rcm' },
            { label: 'Aged Payments', route: '/RCM/AgedPayments', permission: 'AgedPayments', id: 'rcm' },
            { label: 'RCM Analytics', route: '/RCM/rcmanalytics', permission: 'rcmanalytics', id: 'rcm' },
            { label: 'Charge Liquidation', route: '/RCM/chargeliquidation', permission: 'chargeliquidation', id: 'rcm' },
            { label: 'Available Reserve', route: '/RCM/availablereserve', permission: 'availablereserve', id: 'rcm' },
            { label: 'Appointment Census Report', route: '/RCM/appointcensusreport', permission: 'appointcensusreport', id: 'rcm' },

            { label: 'Batch Distribution', route: '/qdashboards/BatchDistribution', permission: 'BatchDistribution', id: 'biotech' },
            { label: 'BIOTECH Configuration', route: '/qdashboards/BiotechConfiguration', permission: 'BiotechConfiguration', id: 'biotech' },
            { label: 'Incomplete Order', route: '/qdashboards/IncompleteOrder', permission: 'IncompleteOrder', id: 'biotech' },
            { label: 'Batch - TRIZETTO', route: '/qdashboards/Trizetto', permission: 'Trizetto', id: 'biotech' },
            { label: 'Rejection Rate', route: '/qdashboards/RejectionRate', permission: 'RejectionRate', id: 'biotech' },

            { label: 'ERA Client Distribution', route: '/qdashboards/ERAClientDistribution', permission: 'ERAClientDistribution', id: 'hub' },
            { label: 'ERA Daywise Distribution', route: '/qdashboards/ERADaywiseDistribution', permission: 'ERADaywiseDistribution', id: 'hub' },
            { label: 'ERA Division Distribution', route: '/qdashboards/ERADivisionDistribution', permission: 'ERADivisionDistribution', id: 'hub' },
            { label: 'Check Distribution', route: '/qdashboards/checkdistribution', permission: 'checkdistribution', id: 'hub' },
            { label: 'File Distribution', route: '/qdashboards/filedistribution', permission: 'filedistribution', id: 'hub' },
            { label: 'EDI HUB Summary', route: '/qdashboards/practicesummary', permission: 'practicesummary', id: 'hub' },
            { label: 'HUB Day Wise Payer Payment', route: '/qdashboards/DayWisePayerPayment', permission: 'DayWisePayerPayment', id: 'hub' },
            { label: 'Honest-Medicare Reconciliation', route: '/qdashboards/honestreport', permission: 'honestreport', id: 'hub' },
            { label: 'New File Distribution', route: '/qdashboards/newfiledistribution', permission: 'newfiledistribution', id: 'hub' },
            { label: 'MHP QSuite-HUB Payment Reconciliation', route: '/qdashboards/mhphubreport', permission: 'mhphubreport', id: 'hub' },

            { label: 'MIU Key Metrics', route: '/miu/keyMetricsMIU', permission: 'keyMetricsMIU', id: 'miu' },
            { label: 'MIU Productivity', route: '/miu/MIUproductivity', permission: 'MIUproductivity', id: 'miu' },
            { label: 'MIU Charges Analysis', route: '/miu/chargesAnalysisMIU', permission: 'chargesAnalysisMIU', id: 'miu' },
            { label: 'MIU Payment Analysis', route: '/miu/paymentAnalysisMIU', permission: 'paymentAnalysisMIU', id: 'miu' },
            { label: 'Expected Value MIU', route: '/miu/ExpectedConfigMIU', permission: 'ExpectedConfigMIU', id: 'miu' },
            { label: 'MIU Daily Tracker', route: '/miu/MIUdailyTracker', permission: 'MIUdailyTracker', id: 'miu' },
            { label: 'MIU Account Receivable', route: '/miu/ARMIUanalysis', permission: 'ARMIUanalysis', id: 'miu' },
            { label: 'MIU Financial Productivity', route: '/miu/miufinancialprod', permission: 'miufinancialprod', id: 'miu' },
            { label: 'MIU Denial Management', route: '/miu/MiuDenialManagement', permission: 'MiuDenialManagement', id: 'miu' },
            { label: 'MIU Appointment Report', route: '/miu/appointment', permission: 'appointment', New: true, id: 'miu' },

            { label: 'Product Usage Analytics', route: '/qdashboards/productusage', permission: 'productusage', id: 'monitor' },
            { label: 'Patient Overall Metrics', route: '/RCM/OverallpatientMetrics', permission: 'OverallpatientMetrics', id: 'monitor' },
            { label: 'Patient Intake & Scheduling KPIS', route: '/qdashboards/Patientintake', permission: 'patientintake', id: 'monitor' },

            { label: 'CPT Allowed vs ASP Pricing', route: '/qdashboards/CPTAllowedvsASPPricing', permission: 'CPTAllowedvsASPPricing', id: 'adhoc' },
            { label: 'MRI Details', route: '/qdashboards/MRIDetails', permission: 'MRIDetails', id: 'adhoc' },

            { label: 'Patient Status Tracking', route: '/qdashboards/patienttracking', permission: 'patienttracking', New: true, id: 'clinical' },
            { label: 'Surgical Order Tracking', route: '/qdashboards/surgicalordertracking', permission: 'surgicalordertracking', New: true, id: 'clinical' },

            { label: 'QBots Dashboard', route: '/qdashboards/qbotdashboard', permission: 'qbotdashboard', id: 'qbot' },
            { label: 'Dashboard Loading Time', route: '/qdashboards/dashboardloadingtime', permission: 'dashboardloadingtime', id: 'qbot' },
            { label: 'Practice Updated Time', route: '/qdashboards/dashboardupdatedtime', permission: 'dashboardupdatedtime', id: 'qbot' },

            { label: 'Practice Configuration', route: '/qdashboards/QAdminPracticeConfiguration', permission: 'QAdminPracticeConfiguration', id: 'configuration' },
            { label: 'Model Configuration', route: '/qdashboards/ModelConfiguration', permission: 'ModelConfiguration', id: 'configuration' },
            { label: 'Provider Configuration', route: '/qdashboards/ProviderConfiguration', permission: 'ProviderConfiguration', id: 'configuration' },
            { label: 'Provider Config by CPT, Facility', route: '/qdashboards/ProviderConfigurationbyPOS', permission: 'ProviderConfigurationbyPOS', id: 'configuration' },
            { label: 'Billing Configuration', route: '/qdashboards/QAdminBillingConfiguration', permission: 'QAdminBillingConfiguration', id: 'configuration' },
            { label: 'Day wise Payer Payment', route: '/qdashboards/QAdminDayWisePayerPayment', permission: 'QAdminDayWisePayerPayment', id: 'configuration' },
            { label: 'Customized Dashboard', route: '/qdashboards/CustomizedDashboard', permission: 'CustomizedDashboard', id: 'configuration' },
            { label: 'Practice Expected Value', route: '/qdashboards/ExpectedValueConfig', permission: 'ExpectedValueConfig', id: 'configuration' },
            { label: 'Denial Config', route: '/qdashboards/DenialConfig', permission: 'DenialConfig', id: 'configuration' },
            { label: 'User Configuration', route: '/qdashboards/RCMUserConfig', permission: 'RCMUserConfig', id: 'configuration' },
            { label: 'TFL/DFL Configuration', route: '/qdashboards/TFLUserConfig', permission: 'TFLUserConfig', id: 'configuration' },
            { label: 'Automail Configuration', route: '/qdashboards/automailconfig', permission: 'automailconfig', id: 'configuration' },
            { label: 'User Default Dashboard Config', route: '/qdashboards/DefaultdashboardConfig', permission: 'DefaultdashboardConfig', New: true, id: 'configuration' },
            { label: 'Internal User Configuration', route: '/qdashboards/internaluserconfiguration', permission: 'internaluserconfiguration', New: true, id: 'configuration' },

            { label: 'Denial Prediction', route: '/mldashboards/denialepayment', permission: 'denialepayment', id: 'ml' },
            { label: 'Forecast Allowed Amount', route: '/mldashboards/forecastalowedpayment', permission: 'forecastalowedpayment', id: 'ml' },

            { label: 'Alliance Summary Analytics', route: '/value/alliancesummaryanalytics', permission: 'alliancesummaryanalytics', id: 'value' },
            { label: 'Practice Summary Analytics', route: '/value/practicesummaryanalytics', permission: 'practicesummaryanalytics', id: 'value' },
            { label: 'Facility Analytics', route: '/value/facilityanalytics', permission: 'facilityanalytics', id: 'value' },
            { label: 'BPCI-HHA Analytics', route: '/value/hhaanalytics', permission: 'hhaanalytics', id: 'value' },
            { label: 'BPCI-SNF Analytics', route: '/value/snfanalytics', permission: 'snfanalytics', id: 'value' },
            { label: 'BPCI-PT Analytics', route: '/value/ptanalytics', permission: 'ptanalytics', id: 'value' },
            { label: 'Orders Analytics', route: '/value/orderanalytics', permission: 'orderanalytics', id: 'value' },

            { label: 'Trend Financial Productivity', route: '/trend/trendfinancialproductivity', permission: 'trendfinancialproductivity', New: true, id: 'trend' },
        ];

        for (let i = 0; i < alldashboardlist.length; i++) {
            if (retrievedObject[alldashboardlist[i].permission] == true) {
                this.dashboardItemList.push(alldashboardlist[i]); // Remove the item
            }
        }

        return this.dashboardItemList;
    }

}