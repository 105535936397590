import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuditLogService } from 'src/app/CallServices/audit-log.service';
import { CallserviceService } from 'src/app/CallServices/callservice.service';
import { SubSink } from 'subsink';

import { CreatedProjectsComponent } from '../../QAdmin/created-projects/created-projects.component';
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { distinct, SortDescriptor } from '@progress/kendo-data-query';
import { TokenEncryptionService } from 'src/app/CallServices/token-encryption.service';
import { DatatransaferService } from 'src/app/ModelFinancialMetrics/CommonService/datatransafer.service';
import { Utility } from "src/app/ModelFinancialMetrics/CommonService/utility";
@Component({
  selector: 'app-specialclaimcreaeproject',
  templateUrl: './specialclaimcreaeproject.component.html',
  styleUrls: ['./specialclaimcreaeproject.component.css']
})
export class SpecialclaimcreaeprojectComponent implements OnInit, OnChanges, OnDestroy {
  // Received Input from parent component
  @Input() InputClaimDetails: any;
  @Input() InputClaimDetailsEdit: any;
  // @Input() InputPracticeDetails: any;
  @Output() OutputResult = new EventEmitter<boolean>();
  public InputVar: any;
  Ownerlist: any[];
  SelectedUser: any;
  Domainuser: any[];
  OwnerListData: any[];
  // NewDatasetName: any;
  OutputSpecialClaimResult(data: any) {
    let outResult = data;
    this.OutputResult.emit(outResult);
    this.ResetComponents();
  }

  constructor(private fb: UntypedFormBuilder,
    private myProjects: CreatedProjectsComponent,
    private toaster: ToastrService,
    private api: CallserviceService,
    private datePipe: DatePipe,
    private datatransfer: DatatransaferService,
    public logapi: AuditLogService,
    private Route: Router,
    public activeModal: NgbActiveModal,
    private tokenEncryptDecrypt: TokenEncryptionService) {
    this.clsUtility = new Utility(toaster);
    this.pageSize = this.clsUtility.pagesize;
    this.data = this.ProjectTypeList.slice(0);
  }
  clsUtility: Utility;
  private subscription = new SubSink();
  private CurrentDataSet: any;
  private CurrentEnvironment: any;
  private CurrentUser: any;
  public pageSize = 0;
  public Selected_Priority: any = '';
  public Selected_Status: any;
  d = new Date();
  public EnddateValue: Date = new Date(
    this.d.getFullYear(),
    this.d.getMonth(),
    this.d.getDate() - 1,
    0,
    0,
    0
  );

  public loadingClaimDetailsGrid = false;
  public practicelist: any;
  public selectedClientkey: any;
  public practicename: any;
  public SelectedSendToUser: any;
  public SelectedSendToUserEmailtxt: any;
  public SelectedCCUser: any;
  public SelectedCCUserEmailtxt: any;
  public Userlist: any;
  public submitted: any;
  public LoaderLabel: any;
  public bIsLoader: any = false;
  public SelectedExternalUserEmailtxt: any;
  public ClaimCount: any;
  public Balance: any;
  public ProjectNamedisplay: any;
  public disabled: boolean = false;
  public disableStatus: boolean = false;
  public SelectedSendToUserEmail: any;
  public SelectedCCUserEmail: any;
  public Descriptiontext: any;
  public bIsshowEditButton: boolean = false;
  public ClaimSelectionEdit: any;
  public min: Date = new Date(
    new DatePipe("en-US").transform(new Date(), "MM/dd/yyyy")
  );
  public projectDetails$: any;
  public RCMUserlist: any;
  public bIsLoaderOwner: boolean = false;
  public bIsLoaderUser: boolean = false;
  public bIsLoaderProjecttype: boolean = false;
  public bIsLoaderClaimcount: boolean = true;
  public bIsLoaderBalance: boolean = true;


  SpecialClaimBatchCreateProjectGroup = this.fb.group({
    fcSpecialclaimProjectName: ["", [Validators.required, Validators.maxLength(150)]],
    fcSpecialclaimStatus: [""],
    fcSpecialclaimPriority: [""],
    fcSpecialclaimTo: ["", Validators.required],
    fcSpecialclaimSendTo: [""],
    fcSpecialclaimCC: [""],
    fcSpecialclaimCCSendTo: [""],
    fcSpecialclaimOther: [""],
    fcSpecialclaimDescription: [""],
    fcSpecialclaimExpectedCompletionDate: ["", Validators.required],
    fcProjectType: [""],
  });

  get ProjectName() {
    return this.SpecialClaimBatchCreateProjectGroup.get("fcSpecialclaimProjectName");
  }
  get Status() {
    return this.SpecialClaimBatchCreateProjectGroup.get("fcSpecialclaimStatus");
  }
  get Priority() {
    return this.SpecialClaimBatchCreateProjectGroup.get("fcSpecialclaimPriority");
  }
  get AssignedTo() {
    return this.SpecialClaimBatchCreateProjectGroup.get("fcSpecialclaimTo");
  }
  get SendToEmail() {
    return this.SpecialClaimBatchCreateProjectGroup.get("fcSpecialclaimSendTo");
  }
  get CC() {
    return this.SpecialClaimBatchCreateProjectGroup.get("fcSpecialclaimCC");
  }
  get CCEmail() {
    return this.SpecialClaimBatchCreateProjectGroup.get("fcSpecialclaimCCSendTo");
  }
  get Description() {
    return this.SpecialClaimBatchCreateProjectGroup.get("fcSpecialclaimDescription");
  }
  get ExpectedCompletionDate() {
    return this.SpecialClaimBatchCreateProjectGroup.get("fcSpecialclaimExpectedCompletionDate");
  }
  get ProjectType() {
    return this.SpecialClaimBatchCreateProjectGroup.get("fcProjectType");
  }

  public Priority_List = [
    { text: "High", value: "High" },
    { text: "Medium", value: "Medium" },
    { text: "Low", value: "Low" },
  ];

  public Status_List = [
    { text: "Assigned", value: "Assigned" },
    { text: "In-process", value: "In-process" },
    { text: "Completed", value: "Completed" },
    { text: "Closed", value: "Closed" },
    { text: "Void", value: "Void" },
  ];


  public bIsCreateProject: false;
  ngOnInit(): void {
    try {
      this.api.projectDetails$.subscribe(val => {
        // deal with asynchronous Observable result
        // console.log('response', val)
        this.InputClaimDetails = val;

        if (!isNullOrUndefined(this.InputClaimDetails)) {
          let claim_balance = 0;
          for (let i = 0; i < this.InputClaimDetails["Claimdetails"].length; i++) {
            if (claim_balance == 0) {
              claim_balance = this.InputClaimDetails["Claimdetails"][i].Balance;
            }
            else {
              claim_balance = claim_balance + this.InputClaimDetails["Claimdetails"][i].Balance;
            }
          }

          this.Balance = claim_balance;
          this.ClaimCount = this.InputClaimDetails["Claimdetails"].length;
          if (this.ClaimCount != 0 && this.Balance != 0) {
            this.bIsLoaderClaimcount = false;
            this.bIsLoaderBalance = false;
          }
        }
      })
      // console.log("ngOnInit special claim this.InputClaimDetails :", this.InputClaimDetails);
      this.ProjectNamedisplay = "";
      this.ClaimCount = 0;
      this.Balance = 0;
      this.submitted = false;
      if (!isNullOrUndefined(this.InputClaimDetailsEdit)) {

      }



      if (!isNullOrUndefined(this.InputClaimDetails)) {
        let claim_balance = 0;
        for (let i = 0; i < this.InputClaimDetails["Claimdetails"].length; i++) {
          if (claim_balance == 0) {
            claim_balance = this.InputClaimDetails["Claimdetails"][i].Balance;
          }
          else {
            claim_balance = claim_balance + this.InputClaimDetails["Claimdetails"][i].Balance;
          }
        }

        this.Balance = claim_balance;
        this.ClaimCount = this.InputClaimDetails["Claimdetails"].length;
        if (this.ClaimCount != 0 && this.Balance != 0) {
          this.bIsLoaderClaimcount = false;
          this.bIsLoaderBalance = false;
        }
      }


      if (environment.currentEnvironment == "Staging") {
        this.CurrentDataSet = "QinsightStagingData";
        this.CurrentEnvironment = "Staging";
      }
      else {
        this.CurrentDataSet = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"))  //Decryption sessionStorage.getItem("currentDataset");
        this.CurrentEnvironment = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment")).toString();
      }


      this.CurrentUser = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("fname"));

      // Set default status
      let status_index = this.Status_List.findIndex(
        (x) => x.text == 'Assigned'
      );
      if (status_index >= 0) {
        this.Selected_Status = this.Status_List[status_index].text;
      }


      if (sessionStorage.getItem("ListofPractice")) {
        this.practicelist = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("ListofPractice"))); //Decryption 
        this.practicelist.sort((a, b) =>
          a.groupname.localeCompare(b.groupname)
        );
        this.selectedClientkey = this.practicelist[0].groupcode;
        this.practicename = this.practicelist[0].groupname;
      }
      else {
        this.Route.navigate(["/qdashboards/AppAccess"])
        return
      }

      if (
        !isNullOrUndefined(this.InputClaimDetailsEdit)
      ) {
        // this.ClaimSelectionEdit = JSON.parse(sessionStorage.getItem("ClaimBatchSelection"));
        this.ClaimSelectionEdit = this.InputClaimDetailsEdit;
        if (this.ClaimSelectionEdit != undefined) {
          if (this.ClaimSelectionEdit.actionType == "Edit") {
            this.bIsLoader = true;
            // this.getProjectData();
            this.disableStatus = false;
            this.bIsshowEditButton = true;
            this.disabled = true;
          } else {
            this.disableStatus = true;
            this.bIsshowEditButton = false;
            this.disabled = false;
          }
        } else {
          this.bIsshowEditButton = false;
          this.disableStatus = true;
          this.disabled = false;
        }
      } else {
        this.bIsshowEditButton = false;
        this.disableStatus = true;
        this.disabled = false;
      }

      //this.GetUserList();
      //this.GetRCMUserList();
      this.GetOwnerandFollowerLIst();
      this.GetProjectType('', 'Retrive');
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  ngOnChanges(changes) {
    try {
      // debugger;
      this.api.projectDetails$.subscribe(val => {
        // deal with asynchronous Observable result
        //  console.log('response', val)
        this.InputClaimDetails = val;

        if (!isNullOrUndefined(this.InputClaimDetails)) {
          let claim_balance = 0;
          for (let i = 0; i < this.InputClaimDetails["Claimdetails"].length; i++) {
            if (claim_balance == 0) {
              claim_balance = this.InputClaimDetails["Claimdetails"][i].Balance;
            }
            else {
              claim_balance = claim_balance + this.InputClaimDetails["Claimdetails"][i].Balance;
            }
          }

          this.Balance = claim_balance;
          this.ClaimCount = this.InputClaimDetails["Claimdetails"].length;
          if (this.ClaimCount != 0 && this.Balance != 0) {
            this.bIsLoaderClaimcount = false;
            this.bIsLoaderBalance = false;
          }
        }
      })
      //  console.log("ngOnChanges special claim this.InputClaimDetails :", this.InputClaimDetails);
      // console.log(this.InputClaimDetails);
      // console.log("ngOninit this.bIsCreateProject : ", this.bIsCreateProject);
      // console.log("ngOnChanges InputClaimDetails", this.InputClaimDetails);
      // console.log("ngOnChanges Input_EditClaimdetails", this.InputClaimDetailsEdit);
      //console.log("ngOnChanges special claim");
      this.submitted = false;
      this.LoaderLabel = "";
      this.ClaimCount = 0;
      this.Balance = 0;
      this.ProjectNamedisplay = "";
      // Get environment, dataset, loginusername
      // this.CurrentEnvironment = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment"));
      //this.CurrentDataSet = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"))  //Decryption sessionStorage.getItem("currentDataset");
      if (environment.currentEnvironment == "Staging") {  //(Staging for Stagingdata )
        this.CurrentDataSet = "QinsightStagingData";
        this.CurrentEnvironment = "Staging";
      }
      else {
        this.CurrentDataSet = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"))  //Decryption sessionStorage.getItem("currentDataset");
        this.CurrentEnvironment = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment")).toString();
      }


      this.CurrentUser = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("fname"));

      // let claim_balance = 0;
      // for (let i = 0; i < this.test_claimdetail.length; i++) {
      //   if (claim_balance == 0) {
      //     claim_balance = this.test_claimdetail[i].Balance;
      //   }
      //   else {
      //     claim_balance = claim_balance + this.test_claimdetail[i].Balance;
      //   }
      // }

      // console.log("ngOnChanges this.InputClaimDetails : ", this.InputClaimDetails);

      if (!isNullOrUndefined(this.InputClaimDetails)) {
        let claim_balance = 0;
        for (let i = 0; i < this.InputClaimDetails["Claimdetails"].length; i++) {
          if (claim_balance == 0) {
            claim_balance = this.InputClaimDetails["Claimdetails"][i].Balance;
          }
          else {
            claim_balance = claim_balance + this.InputClaimDetails["Claimdetails"][i].Balance;
          }
        }

        this.Balance = claim_balance;
        this.ClaimCount = this.InputClaimDetails["Claimdetails"].length;
        if (this.ClaimCount != 0 && this.Balance != 0) {
          this.bIsLoaderClaimcount = false;
          this.bIsLoaderBalance = false;
        }
      }

      // 

      if (
        !isNullOrUndefined(this.InputClaimDetailsEdit)
      ) {
        // this.ClaimSelectionEdit = JSON.parse(sessionStorage.getItem("ClaimBatchSelection"));
        this.ClaimSelectionEdit = this.InputClaimDetailsEdit;
        if (this.ClaimSelectionEdit != undefined) {
          if (this.ClaimSelectionEdit.actionType == "Edit") {
            this.bIsLoader = true;
            // this.getProjectData();
            this.disableStatus = false;
            this.bIsshowEditButton = true;
            this.disabled = true;
          } else {
            this.disableStatus = true;
            this.bIsshowEditButton = false;
            this.disabled = false;
          }
        } else {
          this.bIsshowEditButton = false;
          this.disableStatus = true;
          this.disabled = false;
        }
      } else {
        this.bIsshowEditButton = false;
        this.disableStatus = true;
        this.disabled = false;
      }

      if (environment.currentEnvironment == "Staging") {  //(Staging for Stagingdata )
        this.CurrentDataSet = "QinsightStagingData";
        this.CurrentEnvironment = "Staging";
      }
      else {
        this.CurrentDataSet = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"))  //Decryption sessionStorage.getItem("currentDataset");
        this.CurrentEnvironment = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment")).toString();
      }
      this.CurrentUser = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("fname"));

      // Set default status
      let status_index = this.Status_List.findIndex(
        (x) => x.text == 'Assigned'
      );
      if (status_index >= 0) {
        this.Selected_Status = this.Status_List[status_index].text;
      }


      if (sessionStorage.getItem("ListofPractice")) {
        this.practicelist = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("ListofPractice"))); //Decryption  //this.practicelist = JSON.parse(sessionStorage.getItem("ListofPractice"));
        this.practicelist.sort((a, b) =>
          a.groupname.localeCompare(b.groupname)
        );
        this.selectedClientkey = this.practicelist[0].groupcode;
        this.practicename = this.practicelist[0].groupname;
      }

      //this.GetUserList();
      //this.GetRCMUserList();
      this.GetOwnerandFollowerLIst();
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  ngOnDestroy() {
    try {
      // this.OutputSpecialClaimResult(true);
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  //Old Owner List (TFL Dashboard)
  GetRCMUserList() {
    try {
      this.bIsLoaderOwner = true;
      this.SelectedSendToUser = [];
      this.SelectedSendToUserEmailtxt = "";
      this.RCMUserlist = [];

      const seq = this.api.getRCMUserByRoleId().share();
      seq
        .map((res) => res.json())
        .subscribe((res) => {
          let rcm = res;
          let rcmusers: any;

          if (!isNullOrUndefined(rcm) && rcm.length > 0) {
            let r: {
              id: any,
              userid: any;
              firstname: any;
              lastname: any;
              mobilenumber: any;
              email: any;
              homephone: any;
              usergroups: any;
              displayname: any;
            } = {
              id: '',
              userid: '',
              firstname: '',
              lastname: '',
              mobilenumber: '',
              email: '',
              homephone: '',
              usergroups: [],
              displayname: '',
            };
            for (let i = 0; i < rcm.length; i++) {
              r.id = rcm[i].id;
              r.userid = rcm[i].userid;
              r.firstname = rcm[i].firstname;
              r.lastname = rcm[i].lastname;
              r.mobilenumber = rcm[i].mobilenumber;
              r.email = rcm[i].email;
              r.homephone = rcm[i].homephone;
              r.usergroups = rcm[i].usergroups;
              r.displayname = rcm[i].firstname + " " + rcm[i].lastname;
              this.RCMUserlist.push({ ...r });
            }
            this.bIsLoaderOwner = false;
          }
        });
    } catch (error) {
      this.bIsLoaderOwner = false;
      this.clsUtility.LogError(error);
    }
  }

  //Old Follower List (TFL Dashboard)
  GetUserList() {
    try {
      // this.SelectedSendToUser = [];
      // this.SelectedSendToUserEmailtxt = "";
      this.bIsLoaderUser = true;
      this.SelectedCCUser = [];
      this.SelectedCCUserEmailtxt = "";
      this.Userlist = [];

      const seq = this.api.getUser().share();
      seq
        .map((res) => res.json())
        .subscribe((res) => {
          this.Userlist = res;

          this.bIsLoaderUser = false;
        });
    } catch (error) {
      this.bIsLoaderUser = false;
      this.clsUtility.LogError(error);
    }
  }


  //New Owner and Follower List (TFL Dashboard)
  GetOwnerandFollowerLIst() {
    try {
      this.bIsLoaderOwner = true;
      this.SelectedSendToUser = [];
      this.SelectedSendToUserEmailtxt = "";
      this.Ownerlist = [];
      const seq = this.api.getUser().share();
      seq
        .map((res) => res.json())
        .subscribe((res) => {
          this.Ownerlist = res;
          //debugger;
          const filteredData = this.Ownerlist.filter(obj => obj.email.endsWith('@triarqhealth.com'));
          this.Domainuser = filteredData;
          this.OwnerListData = this.Domainuser.filter((value, index, self) => {
            return self.findIndex(s => s.userid === value.userid) === index;
          });

          this.bIsLoaderOwner = false;

        });
    } catch (error) {
      this.bIsLoaderOwner = false;
      this.clsUtility.LogError(error);
    }
  }












  validateSpecialClaimBatch() {
    try {
      if (
        this.ProjectName.valid &&
        this.AssignedTo.valid &&
        // this.CC.valid &&
        this.ExpectedCompletionDate.valid &&
        !this.clsUtility.CheckEmptyString(this.ProjectName.value) &&
        this.Priority.valid &&
        this.ProjectType.valid
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }


  onSave() {
    try {
      this.submitted = true;
      // this.LoaderLabel = "Saving Details";
      if (this.validateSpecialClaimBatch()) {
        let CreateProjectFromDashboard;
        // this.datatransfer.ClaimBatchCreateProject.subscribe(data => {
        //   CreateProjectFromDashboard = data;
        // });

        // if (CreateProjectFromDashboard == 'AR') {
        this.SaveDynamically();

        // this.Save_AR();
        // }
      }
    } catch (error) {
      // this.OutputpayerEditResult(false);
      this.clsUtility.LogError(error);
    }
  }
  onSelectedChange(e: any): void {
    // console.log(e);
    if (e == true) {
      this.Selected_Priority = 'High';
    }
    else {
      this.Selected_Priority = '';
    }

  }

  SelectSendToUserEmailSingle(user: any) {
    try {
      this.SelectedSendToUserEmail = [];
      this.SelectedSendToUserEmailtxt = "";
      if (user != null && user != undefined) {
        this.SelectedSendToUserEmailtxt =
          this.SelectedSendToUserEmailtxt + user["email"];
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  SelectCCUserEmail(user: any) {
    try {
      // console.log('user',user);
      this.SelectedCCUserEmail = [];
      this.SelectedCCUserEmailtxt = "";
      if (user != null && user != undefined) {
        for (let i = 0; i < user.length; i++) {
          if (i == user.length - 1) {
            this.SelectedCCUserEmailtxt =
              this.SelectedCCUserEmailtxt + user[i]["email"];
          } else {
            this.SelectedCCUserEmailtxt =
              this.SelectedCCUserEmailtxt + user[i]["email"] + ";";
          }
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }
  public obj_project: {
    ProjectName: any,
    Projecttype: any,
    Status: any,
    Priority: any,
    Description: any,
    AssignedToName: any,
    AssignedToEmail: any,
    AssignedCCName: any,
    AssignedCCEmail: any,
    ExternalUserEmail: any,
    AssignedToUserId: any,
    AssignedCC: any,
    ClaimCount: any,
    Balance: any,
  }

  Follower: any[];
  SaveDynamically() {
    try {

      let CurrentUser: any = JSON.parse(this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser")));
      this.toaster.info('Please Wait', 'Saving Project', {
        timeOut: 10000,
        positionClass: "toast-bottom-right",
      });
      const datepipe = new DatePipe("en-US");
      const currentDate = datepipe.transform(
        Date.now(),
        "MM-dd-yyyy"
      );
      let userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid"));
      let userEmail = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("username"))  //Decryption  //sessionStorage.getItem("username");
      let token = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertoken"))  //Decryption //sessionStorage.getItem("usertoken");
      let ClientIP = CurrentUser.visitorip;
      let ClientBrowser = CurrentUser.visitorbrowser;
      let UserName = CurrentUser.userFullName;
      let AssignedSendToName: any = [];
      AssignedSendToName.push(this.AssignedTo.value.displayname);

      // this.Follower : {
      //   AssignedCCId: any,
      //     AssignedCCName: any,
      //       AssignedCCEmail: any,
      // }

      this.Follower = [];
      let AssignedCCName: any = [];
      if (!isNullOrUndefined(this.CC.value)) {
        for (let i = 0; i < this.CC.value.length; i++) {
          let follower: {
            AssignedCCId: any;
            AssignedCCName: any;
            AssignedCCEmail: any;
          } = {
            AssignedCCId: '',
            AssignedCCName: '',
            AssignedCCEmail: '',
          }
          if (!isNullOrUndefined(AssignedCCName)) {
            follower.AssignedCCId = this.CC.value[i].userid;
            follower.AssignedCCName = this.CC.value[i].displayname;
            follower.AssignedCCEmail = this.CC.value[i].username;
            this.Follower.push(follower);
            AssignedCCName.push(this.CC.value[i].displayname);
          }
        }
      }
      else {
        this.Follower = [];
        //AssignedCCName="";

      }

      //console.log("Follwer : ", this.Follower);
      // console.log("AssignedCCName : ", AssignedCCName);
      // this.datatransfer.Obj_CreateProject.subscribe(data => {
      //   this.CreateProject = data;
      // });

      this.obj_project =
      {
        ProjectName: this.ProjectName.value,
        Projecttype: isNullOrUndefined(this.Selected_ProjectType) ? "" : this.Selected_ProjectType['Projecttype'],
        Status: this.Status.value,
        Priority: this.Selected_Priority,
        Description: !isNullOrUndefined(this.Description.value) ? this.Description.value : '',
        AssignedToName: AssignedSendToName.toString(),
        AssignedToEmail: this.SendToEmail.value,
        AssignedCCName: AssignedCCName.toString(),
        AssignedCCEmail: this.CCEmail.value,
        ExternalUserEmail: !isNullOrUndefined(this.SelectedExternalUserEmailtxt)
          ? this.SelectedExternalUserEmailtxt
          : "",
        AssignedToUserId: this.AssignedTo.value.userid,
        AssignedCC: this.Follower.length == 0 ? "[]" : this.Follower,
        ClaimCount: this.ClaimCount.toString(),
        Balance: this.Balance.toString(),
      }
      this.obj_project.Projecttype = isNullOrUndefined(this.obj_project.Projecttype) ? '' : this.obj_project.Projecttype;
      let input_claimbatch: {
        ProjectId: any;
        ProjectName: any;
        ClaimData: any;
        Projecttype: any;
        DatasetName: any;
        PracticeName: any;
        Status: any;
        CreatedOn: any;
        CreatedBy: any;
        CreatedByUserid: any;
        ExpectedCompletionDate: any;
        Priority: any;
        Description: any;
        AssigneeName: any;
        AssigneeEmail: any;
        AssignedToName: any;
        AssignedToEmail: any;
        AssignedOn: any;
        AssignedCCName: any;
        AssignedCCEmail: any;
        ExternalUserEmail: any;
        ClaimCount: any;
        Balance: any;
        AssignedToUserId: any;
        envname: any;
        DML: any;
        AssignedCC: any;
      } = {
        ProjectId: '',
        ProjectName: this.ProjectName.value,
        ClaimData: this.InputClaimDetails["Claimdetails"],


        Projecttype: isNullOrUndefined(this.Selected_ProjectType) ? "" : this.Selected_ProjectType['Projecttype'],
        DatasetName: this.InputClaimDetails["DatasetName"],//'QIDEVDEMO1',//this.CreateProject.Clientkey,
        PracticeName: this.InputClaimDetails["PracticeName"],//'BIOTECH CLINICAL LABORATORIES INC',//this.CreateProject.PracticeName,
        // DatasetName: 'QIDEVDEMO1',//this.CreateProject.Clientkey,
        // PracticeName: 'BIOTECH CLINICAL LABORATORIES INC',//this.CreateProject.PracticeName,
        Status: this.Status.value,
        CreatedOn: this.datePipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.SSSZ').toString(),
        CreatedBy: this.CurrentUser,
        CreatedByUserid: userid,
        ExpectedCompletionDate: this.datePipe
          .transform(this.EnddateValue, "yyyy-MM-dd")
          .toString(),
        Priority: this.Selected_Priority,
        Description: !isNullOrUndefined(this.Description.value) ? this.Description.value : '',
        AssigneeName: this.CurrentUser,
        AssigneeEmail: userEmail,
        AssignedToName: AssignedSendToName.toString(),
        AssignedToEmail: this.SendToEmail.value,
        AssignedOn: this.datePipe
          .transform(Date.now(), "yyyy-MM-ddTHH:mm:ss.SSSZ")
          .toString(),
        AssignedCCName: AssignedCCName.toString(),
        AssignedCCEmail: this.CCEmail.value,
        ExternalUserEmail: !isNullOrUndefined(this.SelectedExternalUserEmailtxt)
          ? this.SelectedExternalUserEmailtxt
          : "",
        ClaimCount: this.ClaimCount.toString(),
        Balance: this.Balance.toString(),
        AssignedToUserId: this.AssignedTo.value.userid,
        envname: this.CurrentEnvironment,
        DML: "Insert",
        AssignedCC: this.Follower.length == 0 ? "[]" : this.Follower,
      }
      input_claimbatch['Projecttype'] = isNullOrUndefined(input_claimbatch['Projecttype']) ? '' : input_claimbatch['Projecttype'];
      //console.log("input_claimbatch : ", input_claimbatch);
      let querystr = "call " + this.CurrentDataSet + ".SP_insertworkqueuemasterdetails('" + this.CurrentDataSet + "','" + JSON.stringify(input_claimbatch) + "','" + input_claimbatch['ProjectId'] + "','" + input_claimbatch['ProjectName'] + "','" + JSON.stringify(input_claimbatch['ClaimData']) + "','" + input_claimbatch['Projecttype'] + "','" + input_claimbatch['DatasetName'] + "','" + input_claimbatch['PracticeName'] + "','" + input_claimbatch['Status'] + "','" + input_claimbatch['CreatedOn'] + "','" + input_claimbatch['CreatedBy'] + "','" + input_claimbatch['CreatedByUserid'] + "','" + input_claimbatch['ExpectedCompletionDate'] + "','" + input_claimbatch['Priority'] + "','" + input_claimbatch['Description'] + "','" + input_claimbatch['AssigneeName'] + "','" + input_claimbatch['AssigneeEmail'] + "','" + input_claimbatch['AssignedToName'] + "','" + input_claimbatch['AssignedToEmail'] + "','" + input_claimbatch['AssignedOn'] + "','" + input_claimbatch['AssignedCCName'] + "','" + input_claimbatch['AssignedCCEmail'] + "','" + input_claimbatch['ExternalUserEmail'] + "','" + input_claimbatch['ClaimCount'] + "','" + input_claimbatch['Balance'] + "','" + input_claimbatch['AssignedToUserId'] + "','" + input_claimbatch['DML'] + "','" + JSON.stringify(input_claimbatch['AssignedCC']) + "','savedata')";
      //this.NewDatasetName= input_claimbatch['DatasetName'];
      let param:
        {
          querystring: string,
          loginuser: any,
          clientip: any,
          clientbrowser: any,
          authorization: any,
          follower: any,
          type: any,
          clientkey: any,
          projectdetails: any,
          //NewDatasetName:any;
        } = {
        querystring: querystr,
        loginuser: UserName,
        clientip: ClientIP,
        clientbrowser: ClientBrowser,
        authorization: "Bearer " + token,
        follower: this.Follower,
        type: 'Insert',
        clientkey: this.CurrentDataSet,
        projectdetails: input_claimbatch,
        // NewDatasetName:this.NewDatasetName,
      };
      this.api.getFunctionName("SpecialClaimDynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            //debugger;
            // sessionStorage.removeItem("ClaimBatchSelection");
            if (!isNullOrUndefined(res) && res.length > 0 && res.length > 0) {

              if (res[0]["ProjectId"] != '' && res[0]["ProjectId"].length > 0) {
                // this.clsUtility.showSuccess('Claims Project ' + this.obj_project.ProjectName + ' is created successfully');
                this.toaster.info('Claims Project ' + this.obj_project.ProjectName + ' is created successfully', '', {
                  timeOut: 10000,
                  positionClass: "toast-bottom-right",
                });
                this.Save_SendEmail(res[0]["ProjectId"]);
                this.logapi.insertActivityLog(
                  this.obj_project.ProjectName + " project inserted successfully",
                  "Create Project",
                  "Read"
                );
                this.onCancel(true);
                // this.bIsLoader = false;
                // document.getElementById('hidemodal').click();
                $(".ppClose").click();
                // this.OutputSpecialClaimResult(true);
              }
              else {
                this.bIsLoader = false;
                this.clsUtility.showError("Error while creating project");
              }
            } else {
              this.bIsLoader = false;
              this.clsUtility.showError("Error while creating project");
            }

          },
          (err) => {
            this.bIsLoader = false;
            this.clsUtility.showWarning(err);
            //console.log('what is error',err);
            this.logapi.insertActivityLog(
              this.ProjectNamedisplay + " error while creataing project",
              "Create Project",
              "Read"
            );
          }
        );
      this.activeModal.close(false);
      // $(".ppClose").click();
    } catch (error) {
      this.clsUtility.LogError(error)
      //console.log("error",error);
    }
  }

  public ProjectDetails: any;

  onCancel(bIsClose) {
    try {
      this.activeModal.close(bIsClose);
      this.ProjectDetails = undefined;
      this.ClaimSelectionEdit = undefined;
      this.OutputSpecialClaimResult(bIsClose);
      // sessionStorage.removeItem("ClaimBatchSelection");
      // this.OutputpayerEditResult(false);
      // $("#arCreateProjectModal").modal("hide");
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  onCloseModal() {
    try {
      // if (!isNullOrUndefined(this.ClaimSelectionEdit) && this.ClaimSelectionEdit.actionType == "Edit") {
      this.myProjects.getScoreCardData();
      this.myProjects.getProjectsData();
      // }
      this.OutputSpecialClaimResult(true);
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  ResetComponents() {
    try {
      this.submitted = false;

      this.AssignedTo.reset();
      this.SendToEmail.reset();
      this.SpecialClaimBatchCreateProjectGroup.reset();
      this.ClaimCount = 0;
      this.Balance = 0;
      // this.Selected_Priority = "High";
      // this.Selected_Status = "Open";
      // Set default status
      let status_index = this.Status_List.findIndex(
        (x) => x.text == 'Assigned'
      );
      if (status_index >= 0) {
        this.Selected_Status = this.Status_List[status_index].text;
      }
      //Set default priority
      // let priority_index = this.Priority_List.findIndex(
      //   (x) => x.text == 'High'
      // );
      // if (priority_index >= 0) {
      //   this.Selected_Priority = this.Priority_List[priority_index].text;
      // }
      this.SelectedSendToUser = [];
      this.SelectedSendToUserEmailtxt = '';
      this.SelectedCCUser = [];
      this.SelectedCCUserEmailtxt = '';
      this.subscription.unsubscribe();
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  Save_SendEmail(ProjectId: any) {
    try {
      const datepipe = new DatePipe("en-US");
      const currentDate = datepipe.transform(Date.now(), "MM-dd-yyyy");

      let ClaimSelection: any = sessionStorage.getItem("ClaimBatchSelection");
      let Claim = JSON.parse(ClaimSelection);
      let AssignedSendToName: any = [];
      AssignedSendToName.push(this.obj_project.AssignedToName);

      let input_claimbatch: {
        envname: any;
        DatasetName: any;
        PracticeName: any;
        ProjectId: any;
        ProjectName: any;
        Status: any;
        Priority: any;
        AssignedToName: any;
        AssignedToEmail: any;

        Description: any;
        ExpectedCompletionDate: any;
        ExternalUserEmail: any;
        ClaimCount: any;
        Balance: any;
        CreatedOn: any;
        CreatedBy: any;
        AssigneeName: any;
        AssignedOn: any;
        AssignedToUserId: any;
        DML: any;
        Projecttype: any;
      } = {
        envname: this.CurrentEnvironment,
        DatasetName: this.InputClaimDetails["DatasetName"],
        ProjectId: ProjectId,
        PracticeName: this.InputClaimDetails["PracticeName"],
        ProjectName: this.obj_project.ProjectName,//this.ProjectName.value,
        Status: this.obj_project.Status,//this.Status.value,
        Priority: this.obj_project.Priority,//this.Priority.value,
        AssignedToName: this.obj_project.AssignedToName,//AssignedSendToName.toString(),
        AssignedToEmail: this.obj_project.AssignedToEmail,//!isNullOrUndefined(this.SendToEmail.value) ? this.SendToEmail.value : '',
        Description: this.obj_project.Description,//!isNullOrUndefined(this.Description.value) ? this.Description.value : '',
        ExpectedCompletionDate: this.datePipe
          .transform(this.EnddateValue, "yyyy-MM-dd")
          .toString(),
        ExternalUserEmail: !isNullOrUndefined(this.SelectedExternalUserEmailtxt)
          ? this.SelectedExternalUserEmailtxt
          : "",
        ClaimCount: this.obj_project.ClaimCount,
        Balance: this.obj_project.Balance,
        CreatedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSS")
          .toString(),
        CreatedBy: this.CurrentUser,
        AssigneeName: this.CurrentUser,
        AssignedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSS")
          .toString(),
        AssignedToUserId: this.obj_project.AssignedToUserId,//this.AssignedTo.value.userid,
        DML: "Insert",
        Projecttype: this.obj_project.Projecttype,
      };

      let input_data: {
        data: any;
      } = {
        data: input_claimbatch,
      };

      this.api.getFunctionName("workqueuemail");
      let seq = this.api.BindDataRCM(input_data).share(); //workqueuemail is in rcm
      this.subscription.add(
        seq.subscribe(
          (response) => {
            if (response != null && response != undefined) {
              // console.log("response : ", response);
              let result: any = response;
              if (result.length > 0) {
                let res: any = result._body;
                if (res == "Email Send and Data Insert Successfully Done") {


                } else if (res == "Unsuccessfull Email Send") {
                  this.clsUtility.showError("Email not sent");

                }
              } else {
                this.clsUtility.showError("Email not sent");

              }
            } else {
              this.clsUtility.showError("Email not sent");

            }


          },
          (err) => {
            this.clsUtility.LogError(err);

          }
        )
      );
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }
  public Selected_ProjectType: any = '';
  public filter: string;
  public ProjectTypeList: Array<{ Projecttype: string; Projecttypeid: number }> = [
    { Projecttype: "Small", Projecttypeid: 1 },
    { Projecttype: "Medium", Projecttypeid: 2 },
    { Projecttype: "Large", Projecttypeid: 3 },
  ];

  public data: Array<{ Projecttype: string; Projecttypeid: number }>;
  public addNew(): void {
    this.ProjectTypeList.push({
      Projecttype: this.filter,
      Projecttypeid: 0,
    });
    this.handleFilter(this.filter);
  }

  public handleFilter(value) {
    this.filter = value;
    this.data = this.ProjectTypeList.filter(
      (s) => s.Projecttype.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  GetProjectType(ProjectType: string, operationtype: string) {
    try {
      // this.bIsLoader = true;
      this.bIsLoaderProjecttype = true;
      let input_claimbatch: {
        commondatabasename: any;
        type: any;
        Projecttype: any;
      } = {
        commondatabasename: this.CurrentDataSet,
        type: operationtype,
        Projecttype: ProjectType,
      };

      let querystr = "call " + this.CurrentDataSet + ".SP_insertprojecttype('" + JSON.stringify(input_claimbatch) + "')";
      let param: { querystring: string } = { querystring: querystr };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (!isNullOrUndefined(res)) {
              if (res.length > 0 && res[0]['f0_'] != '[]' && res[0]['f0_'] == 'New Project Type Added') {
                this.logapi.insertActivityLog(
                  "Projecttype " + ProjectType + " added successfully",
                  "Create Project",
                  "Read"
                );
                this.data = [];
                this.data = res;
                this.ProjectTypeList = [];
                this.ProjectTypeList = res;
                if (!isNullOrUndefined(this.ProjectTypeList)) {
                  this.data = this.ProjectTypeList.slice(0);
                }
                // this.bIsLoader = false;
                this.bIsLoaderProjecttype = false;
              }
              else if (res.length > 0 && res[0]['f0_'] != '[]' && res[0]['f0_'] == 'Project Type Already Exists') {
                this.clsUtility.showInfo("Project type already exists");
                this.bIsLoader = false;
              }
              else if (res.length > 0 && res != '[]') {
                this.data = [];
                this.data = res;
                this.ProjectTypeList = [];
                this.ProjectTypeList = res;
                if (!isNullOrUndefined(this.ProjectTypeList)) {
                  this.data = this.ProjectTypeList.slice(0);
                }
                // this.bIsLoader = false;
                this.bIsLoaderProjecttype = false;
              }
              else if (res != '[]') {
                this.data = [];
                this.data = res;
                this.ProjectTypeList = [];
                this.ProjectTypeList = res;
                if (!isNullOrUndefined(this.ProjectTypeList)) {
                  this.data = this.ProjectTypeList.slice(0);
                }
                // this.bIsLoader = false;
                this.bIsLoaderProjecttype = false;
              }
            }
          },
          (err) => {
            // this.bIsLoader = false;
            this.bIsLoaderProjecttype = false;
            this.clsUtility.showWarning(err);
            this.logapi.insertActivityLog(
              this.ProjectNamedisplay + " error while inserting projecttype",
              "Create Project",
              "Read"
            );
          }
        );
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }
}
function isNullOrUndefined(value: any): boolean { return value === null || value === undefined; }
