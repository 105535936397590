import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { AuditLogService } from './audit-log.service';
import { TokenEncryptionService } from './token-encryption.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  username: any = '';

  constructor(private db: AngularFireDatabase, public logapi: AuditLogService, private tokenEncryptDecrypt: TokenEncryptionService,) { }

  getMaintenanceMode(): Observable<any> {
    return this.db.object("Qinsight/maintenanceMode/").valueChanges();
  }

  setMaintenanceMode(value: boolean): void {
    this.username = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("username"))
    this.db.object('/Qinsight/maintenanceMode').set({ maintenanceMode: value, })
      .then(() => this.logapi.insertActivityLog(
        "Maintenance mode set by " + this.username,
        "Maintenance mode set to " + value,
        "Write"
      ))
      .catch(error => console.error('Error setting maintenance mode:', error));
  }

  getNetworkKey(): Observable<any> {
    return this.db.object("Qinsight/netSecurity/").valueChanges();
  }
}