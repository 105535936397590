import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Utility } from "src/app/ModelFinancialMetrics/CommonService/utility";
import { ToastrService } from "ngx-toastr";
import { CallserviceService } from "src/app/CallServices/callservice.service";
import {
  Filter,
  OutputFilter,
  PreserveFilterInput,
} from "src/app/ModelFinancialMetrics/CommonService/filter"
import { AuditLogService } from "src/app/CallServices/audit-log.service";

import { SubSink } from "subsink";
import { Router } from '@angular/router';
import { TokenEncryptionService } from "src/app/CallServices/token-encryption.service";
import { FilterDataService } from "src/app/ModelFinancialMetrics/CommonService/filter-data.service";

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit, OnDestroy {
  selectedPracticenNew: any;
  npiId: any;
  bisyearMetrics: boolean;
  selectedYearNumber: any;
  bIstrue: boolean;
  bIsMonthIntervalKM: boolean;
  selectedMonthIntervalKM: any;
  bIsyearNumber: boolean;
  sNPI_id: string;
  provider_id: any;
  filterjson: any;
  bisNPI: boolean = false;
  SelectedPractice: any;
  Provider_TypeDisplay: any;
  UserType: string;
  PracticeListNPI: any[] = [];
  nProviderID: any;


  PracticeCPTList = [];
  public selectedCPTName: any[] = [];
  bIsProcedure: any;
  selectedProcedure: any;
  CPT_TypeDisplay: string;
  CPTDropdownSettings = {};
  public selectedCPT_Display: any = [];
  SelectedCPT_Badges: string;
  bIsLoader: boolean;
  bIsLoaderCptTypes: boolean;
  bIsProcedureval: any;
  D_selectedCPTName: any[];
  ProviderId: string;
  ProviderIdList: any = {}
  disabled: boolean;
  IsselectedPractice: boolean;
  userType: string;
  SelectedPractice1: any;
  isMonthSelected: any;
  hidebutton: boolean;


  constructor(
    private fb: UntypedFormBuilder,
    private toaster: ToastrService,
    private api: CallserviceService,
    public _router: Router,
    private logapi: AuditLogService,
    private filterdata: FilterDataService,
    private tokenEncryptDecrypt: TokenEncryptionService
  ) {
    this.clsUtility = new Utility(toaster);
  }

  // Input - Output Parameter
  @Input() SelectFilter: Filter;
  @Input() selectedClientKey: any;
  @Output() FilterData = new EventEmitter<any>();

  clsUtility: Utility;
  private subscription = new SubSink();
  FilterJSON: Filter; //Input for filter json
  AllFilterJSON: OutputFilter; //Output of filter json
  ListPractices: any;
  CurrentDataset: any;
  DisplaySelectedPractice: any;
  selectedMaxbDays: any;
  // Public Parameters
  public bIsGroup: boolean;
  public bIsPractice: boolean;
  public bIsMonthInterval: boolean;
  public bIsYear: boolean;
  public bIsProvider: boolean;
  public bIsProceduretype: boolean; //provider prod
  public bIsStatus: boolean;
  public bIsSinglePractice: boolean;
  public bIsSingleMonth: boolean;
  public bIsMonth: boolean;
  public bIsStartDate: boolean;
  public bIsEndDate: boolean;
  public bIsMinDays: boolean;
  public bIsMaxDays: boolean;
  public bIsPracticeProvider: boolean;

  public GroupList: any;
  public PracticeList: any;
  public PracticeProviderList: any[] = [];

  //25 months for keymetrics
  public MonthIntervalListofKeyMetrics = [
    { text: "Last Month", value: "1" },
    { text: "Last 3 Months", value: "3" },
    { text: "Last 6 Months", value: "6" },
    { text: "Last 9 Months", value: "9" },
    { text: "Last 12 Months", value: "12" },
    { text: "Last 25 Months", value: "25" },
  ];

  public MonthIntervalList = [
    { text: "Last Month", value: "1" },
    { text: "Last 3 Months", value: "3" },
    { text: "Last 6 Months", value: "6" },
    { text: "Last 9 Months", value: "9" },
    { text: "Last 12 Months", value: "12" },
    { text: "Last 25 Months", value: "25" },

  ];

  //Combobox in KM

  selectedTypeofCompare: any;
  bIsComparision: any;
  public CompareList = [
    { text: "MoM - Month Over Month", value: "Monthwise" },
    { text: "YoY - Year Over Year", value: "yearwise" },



  ];

  public statusList = [
    { text: "Batch", value: "Batch" },
    { text: "SendToClaimManager", value: "SendToClaimManager" },
    { text: "SendToClearingHouse", value: "SendToClearingHouse" },
  ];
  public YearList: any;
  public ProviderList = [
    { text: "Billing Provider", value: "Billing Provider" },
    { text: "Rendering Provider", value: "Rendering Provider" },
  ];
  public MonthList = [
    { text: "January", value: "1" },
    { text: "February", value: "2" },
    { text: "March", value: "3" },
    { text: "April", value: "4" },
    { text: "May", value: "5" },
    { text: "June", value: "6" },
    { text: "July", value: "7" },
    { text: "August", value: "8" },
    { text: "September", value: "9" },
    { text: "October", value: "10" },
    { text: "November", value: "11" },
    { text: "December", value: "12" },
  ];

  public CPTType_List = [

    { text: "Procedure Codes", value: "cptcode" },
    { text: "Procedure Groups", value: "cptgroup" },
  ];

  // daterange filter
  d = new Date();
  monthfirstday = (this.d.getFullYear(), this.d.getMonth() - 1, 1);
  public StartdateValue: Date = new Date(
    this.d.getFullYear(),
    this.d.getMonth(),
    this.monthfirstday,
    0,
    0,
    0
  );
  public EnddateValue: Date = new Date(
    this.d.getFullYear(),
    this.d.getMonth(),
    this.d.getDate(),
    0,
    0,
    0
  );

  public selectedGroup: any;
  public selectedPractice: any;
  public selectedMonthInterval: any;
  public selectedYear: any;
  public selectedProvider: any;
  public selectedProviderName: any;
  public selectedMonth: any;
  public UserLastSelection: any;
  public selectedStartDate: any;
  public selectedEndDate: any;
  public selectedMinDays: Number = 15;
  public selectedMaxDays: Number = 30;
  private multipleselection: any;
  private singleselection: any;
  public selectedStatus: any;

  public providerkeyList: any;

  // Form Controls
  FilterFormGroup = this.fb.group({
    fcFilterGroup: [""],
    fcFilterPractice: [""],
    fcFilterMonthInterval: [""],
    fcFilterProviderName: [""],
    fcFilterYear: [""],
    fcFilterProvider: [""],
    fcFilterMonth: [""],
    fcStartDate: "",
    fcEndDate: "",
    fcFilterMinDays: this.selectedMinDays,
    fcmaxd: this.selectedMaxDays,
    fcStatus: [""],
    fcFilterMaxDays: this.selectedMaxDays,
    isonHoldChecked: "",
    fcFilterProcedureName: [""],
    fcFilterProcedure: [""],


  });
  get fbcFilterGroup() {
    return this.FilterFormGroup.get("fcFilterGroup");
  }
  get fbcFilterPractice() {
    return this.FilterFormGroup.get("fcFilterPractice");
  }
  get fbcFilterMonthInterval() {
    return this.FilterFormGroup.get("fcFilterMonthInterval");
  }

  get fbcFilterProviderName() {
    return this.FilterFormGroup.get("fcFilterProviderName");
  }

  get fcFilterProcedureName() {
    return this.FilterFormGroup.get("fcFilterProcedureName");
  }
  get fcFilterProcedure() {
    return this.FilterFormGroup.get("fcFilterProcedure");
  }
  get fbcFilterYear() {
    return this.FilterFormGroup.get("fcFilterYear");
  }
  get fbcFilterProvider() {
    return this.FilterFormGroup.get("fcFilterProvider");
  }
  get fbcFilterStatus() {
    return this.FilterFormGroup.get("fcStatus");
  }
  get fbcFilterMonth() {
    return this.FilterFormGroup.get("fcFilterMonth");
  }
  get fbStartDate() {
    return this.FilterFormGroup.get("fcStartDate");
  }
  get fbMinDays() {
    return this.FilterFormGroup.get("fcFilterMinDays");
  }
  get fbMaxD() {
    return this.FilterFormGroup.get("fcFilterMinDays");
  }
  get fbMaxDays() {
    return this.FilterFormGroup.get("fcmaxd");
  }
  get isOnHoldChecked_fun() {
    return this.FilterFormGroup.get("isonHoldChecked");
  }

  //SinglePractice filters
  bisProviderdash: boolean;



  Onholddata: any = "false";
  OnholddataChecked: any = true;
  bIsOnHold: boolean;
  onHoldComponent: boolean;
  ngOnInit() {
    try {
      //debugger;
      this.selectedTypeofCompare = "Monthwise Comparison";
      // this.selectedProcedure = "cptcode";
      // let cptindex = this.CPTType_List.findIndex(x => x.value == this.selectedProcedure);
      // if (cptindex >= 0) {
      //   this.CPT_TypeDisplay = this.CPTType_List[cptindex].text;
      // }
      // this.selectedProvider = "Billing Provider";
      // let providerindex = this.ProviderList.findIndex(x => x.value == this.selectedProvider);
      // if (providerindex >= 0) {
      //   this.Provider_TypeDisplay = this.ProviderList[providerindex].text;
      // }

      this.ListPractices = [];
      //this.CurrentDataset ="QinsightQAData";
      //this.sNPI_id= "5627182012" ; //for testing purpose added NPI and provider id;
      //this.sNPI_id= ""; //for normal dashboard
      this.CurrentDataset = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"));//Decryption sessionStorage.getItem("currentDataset");
      this.sNPI_id = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("sNPI")).toString().trim();
      this.userType = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertype")).toString();

      if (sessionStorage.getItem("listwithAllPractice")) {
        this.ListPractices = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("listwithAllPractice")));
      }
      else{
        this._router.navigate(["/qdashboards/AppAccess"])
      return

      }
      //   if(this.npiId){  //all RCM practice 
      //     //this.ListPractices = JSON.parse( sessionStorage.getItem("listwithAllPractice"));
      //      this.ListPractices = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("listwithAllPractice")));  //Decryption
      //   }
      //  else{
      //   //this.ListPractices = JSON.parse(sessionStorage.getItem("listwithAllPractice"));
      //   this.ListPractices = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("listwithAllPractice")));  //Decryption
      //  }

      this.FilterJSON = this.SelectFilter;
      if (!isNullOrUndefined(this.FilterJSON)) {
        this.bIsGroup = this.FilterJSON.group;
        this.bIsPractice = this.FilterJSON.practice;
        this.bIsMonthInterval = this.FilterJSON.monthinterval;
        this.bIsMonthIntervalKM = this.FilterJSON.monthintervalKM;
        this.bIsYear = this.FilterJSON.year;
        this.bIsProvider = this.FilterJSON.provider;
        this.bIsProceduretype = this.FilterJSON.proceduretype; //Provider Prod DB
        this.bIsProcedureval = this.FilterJSON.procedurevalue;
        this.bIsSinglePractice = this.FilterJSON.singlepractice;
        this.bisProviderdash = this.FilterJSON.bisProviderdash;
        this.bIsMonth = this.FilterJSON.month;
        this.bIsSingleMonth = this.FilterJSON.singlemonth;
        this.bIsyearNumber = this.FilterJSON.yearNumber;
        this.bIsComparision = this.FilterJSON.MonthOrYearWise;
        this.bIsStartDate = this.FilterJSON.startdate;
        this.bIsEndDate = this.FilterJSON.enddate;
        this.bIsMinDays = this.FilterJSON.MinDays;
        this.bIsMaxDays = this.FilterJSON.MaxDays;
        this.bIsPracticeProvider = this.FilterJSON.ProviderName;
        this.bIsStatus = this.FilterJSON.Status;
        this.onHoldComponent = this.FilterJSON.onHold;
      }
      this.CPTDropdownSettings = {
        singleSelection: false,
        text: "All Procedure",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        enableSearchFilter: true,
        classes: "myclass custom-class-example",
        noDataLabel: "No Procedure is active",
        lazyLoading: true,
        badgeShowLimit: 2,
        disabled: false,
      };

      this.RetriveFilterData();
    } catch (error) {
      console.log('err', error)
      this.clsUtility.LogError(error);
    }
  }

  ngOnDestroy() {
    try {
      this.subscription.unsubscribe();
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  RetriveDefaultFilters() {
    try {
      //if condition when we come from practices dashboard
      if (isNullOrUndefined(this.selectedClientKey) || this.selectedClientKey == '') {
        // this.UserLastSelection = JSON.parse( sessionStorage.getItem("UserLastSelection"));  //old
        this.UserLastSelection = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("UserLastSelection")))  //Decryption


      }
      if (!isNullOrUndefined(this.UserLastSelection)) {
        this.multipleselection =
          this.UserLastSelection.multipleselection.length > 0
            ? this.UserLastSelection.multipleselection
            : [];
        this.singleselection =
          this.UserLastSelection.singleselection.length > 0
            ? this.UserLastSelection.singleselection
            : [];
      }


      if (this.bIsGroup) {
        this.GroupList = this.filterdata.GetUserGroups();
        if (!isNullOrUndefined(this.GroupList) && this.GroupList.length > 0) {
          this.selectedGroup = this.GroupList[0];
        } else {
          this.bIsGroup = false;
        }
      }

      if (this.bIsProceduretype) { //CPT Type Filter of Provider Productivity on load
        //debugger;
        this.selectedProcedure = [];
        if (
          !isNullOrUndefined(this.CPTType_List) &&
          this.CPTType_List.length > 0
        ) {
          let cptindex = this.CPTType_List.findIndex(
            (x) => x.value == this.singleselection[0].procedureType
          );
          if (cptindex >= 0) {
            this.selectedProcedure = this.CPTType_List[cptindex].value;
          }
        }
      }



      if (this.bIsPractice) {
        this.PracticeList = this.filterdata.GetUserPractices();
        if (
          !isNullOrUndefined(this.PracticeList) &&
          this.PracticeList.length > 0
        ) {
          if (!this.bIsGroup) {
            this.GroupList = this.filterdata.GetUserGroups();
            if (
              !isNullOrUndefined(this.GroupList) &&
              this.GroupList.length > 0
            ) {
              for (let i = 0; i < this.GroupList.length; i++) {
                let gindex = this.PracticeList.findIndex(
                  (x) => x.groupid == this.GroupList[i].groupid
                );
                if (gindex >= 0) {
                  this.PracticeList.splice(gindex, 1);
                }
              }
              this.GroupList = null;
            }
          }

          if (!this.bIsSinglePractice) {
            if (
              !isNullOrUndefined(this.GroupList) &&
              this.GroupList.length == 1
            ) {
              let index = this.PracticeList.findIndex((x) => x.groupid == 0);
              if (index >= 0) {
                this.PracticeList.splice(index, 1);
              }
            }

            this.selectedPractice = [];
            if (this.PracticeList.length == 1) {
              this.selectedPractice.push(this.PracticeList[0]);
            } else {
              this.SelectGroup(this.selectedGroup);
            }
          }
          else if (this.bIsSinglePractice) {
            //debugger;
            if (this.PracticeList.length == 1) {
              this.selectedPractice = this.PracticeList[0];
            } else {
              let index = this.PracticeList.findIndex((x) => x.groupid == 0);
              if (index >= 0) {
                this.PracticeList.splice(index, 1);
              }
              this.selectedPractice = this.PracticeList[0];
            }

          }

          if (this.bisProviderdash) {
            if (this.userType == "TRIARQ User")  //normal dashboard for triarq user
            { // if (this.sNPI_id == 'null' || this.sNPI_id == "" || this.sNPI_id == undefined) {
              this.sNPI_id = "";
              if (this.UserLastSelection.singleselection.length > 0) {
                this.getProviderslistNew(this.singleselection[0].groupcode);
                this.getCPTlistNew(this.singleselection[0].groupcode, this.selectedProcedure);
              } else {
                this.getProviderslistNew(this.selectedPractice.groupcode);
                this.getCPTlistNew(this.singleselection[0].groupcode, this.selectedProcedure);
              }
            }
            else if (this.userType == "Provider") { //only for provider
              if (this.UserLastSelection.singleselection.length > 0) {
                this.getProviderslistNPI(this.singleselection[0].groupcode);
                this.getCPTlistNPI(this.singleselection[0].groupcode, this.selectedProcedure);
              } else {
                this.getProviderslistNPI(this.selectedPractice.groupcode);
                this.getCPTlistNPI(this.selectedPractice.groupcode, this.selectedProcedure);
              }
            }
            else {
              this._router.navigate(["/qdashboards/AppAccess"]);  //hide for patient and Practice User
              return true;
            }
          }
        } else {
          this.bIsPractice = false;
        }
      }

      //12 months for Provider productivity 
      if (this.bIsMonthInterval && this.bisProviderdash) {
        let index = this.MonthIntervalList.findIndex((x) => x.value == "6");
        if (index > 0) {
          this.selectedMonthInterval = this.MonthIntervalList[index];
        }
      }

      //// 25 months for Keymetrics
      if (this.bIsMonthIntervalKM) {
        let index = this.MonthIntervalListofKeyMetrics.findIndex((x) => x.value == "6");
        if (index > 0) {
          this.selectedMonthIntervalKM = this.MonthIntervalListofKeyMetrics[index];
        }
      }

      if (this.bIsYear) {
        this.YearList = [];
        let year: { text: string; value: string } = { text: "", value: "" };
        for (let i = 0; i < 5; i++) {
          year.text = (new Date().getFullYear() - i).toString();
          year.value = (new Date().getFullYear() - i).toString();
          this.YearList.push({ ...year });
        }
        this.selectedYear = this.YearList[0];
      }

      if (this.bIsProvider) {
        let index = this.ProviderList.findIndex(
          (x) => x.value == "Billing Provider"
        );
        if (index >= 0) {
          this.selectedProvider = this.ProviderList[index].value;
        }
      }

      // if (this.bIsProceduretype) {
      //   let index = this.CPTType_List.findIndex(
      //     (x) => x.value == "cptcode"
      //   );
      //   if (index >= 0) {
      //     this.selectedProcedure = this.CPTType_List[index].value;
      //   }
      // }

      if (this.bIsMonth) {
        this.selectedMonth = [];
        for (let i = 0; i < 3; i++) {
          let nmonth = new Date().getMonth() - i;
          let index = this.MonthList.findIndex(
            (x) => x.value == nmonth.toString()
          );
          if (index >= 0) {
            this.selectedMonth.push(this.MonthList[index]);
          }
        }
      }

      if (!isNullOrUndefined(this.bIsSinglePractice)) {
        if (
          !isNullOrUndefined(this.singleselection) &&
          this.singleselection.length > 0
        ) {
          if (this.bIsGroup) {
            if (
              !isNullOrUndefined(this.GroupList) &&
              this.GroupList.length > 0
            ) {
              let groupindex = this.GroupList.findIndex(
                (x) => x.groupid == this.singleselection[0].parentGroupID
              );
              if (groupindex >= 0) {
                this.selectedGroup = this.GroupList[groupindex];
              }
            }
          }

          if (this.bIsPractice) {
            if (
              !isNullOrUndefined(this.PracticeList) &&
              this.PracticeList.length > 0
            ) {
              let practiceindex = this.PracticeList.findIndex(
                (x) => x.groupcode == this.singleselection[0].groupcode
              );
              if (practiceindex >= 0) {
                this.selectedPractice = [];
                this.selectedPractice = this.PracticeList[practiceindex];
              }
            }
          }

          //12 months for Provider productivity 
          if (this.bIsMonthInterval && this.bisProviderdash) {
            if (
              !isNullOrUndefined(this.MonthIntervalList) &&
              this.MonthIntervalList.length > 0
            ) {
              let monthindex = this.MonthIntervalList.findIndex(
                (x) => x.value == this.singleselection[0].monthInterval
              );
              if (monthindex > 0) {
                this.selectedMonthInterval = this.MonthIntervalList[monthindex];
              }
            }
          }

          // // 25 months for Keymetrics
          if (this.bIsMonthIntervalKM) {
            if (
              !isNullOrUndefined(this.MonthIntervalListofKeyMetrics) &&
              this.MonthIntervalListofKeyMetrics.length > 0
            ) {
              let monthindex = this.MonthIntervalListofKeyMetrics.findIndex(
                (x) => x.value == this.multipleselection[0].monthInterval
              );
              if (monthindex > 0) {
                this.selectedMonthIntervalKM = this.MonthIntervalListofKeyMetrics[monthindex];
              }
            }
          }

          if (this.bIsYear) {
            if (!isNullOrUndefined(this.YearList) && this.YearList.length > 0) {
              let yearindex = this.YearList.findIndex(
                (x) => x.value == this.singleselection[0].year
              );
              if (yearindex > 0) {
                this.selectedYear = this.YearList[yearindex];
              }
            }
          }

          if (this.bIsProvider) {

            if (
              !isNullOrUndefined(this.ProviderList) &&
              this.ProviderList.length > 0
            ) {
              let providerindex = this.ProviderList.findIndex(
                (x) => x.value == this.singleselection[0].providerType
              );
              if (providerindex >= 0) {
                this.selectedProvider = this.ProviderList[providerindex].value;
              }
            }
          }
          // if (this.bIsProceduretype) { //CPT Type Filter of Provider Productivity
          //   // debugger;
          //   if (
          //     !isNullOrUndefined(this.CPTType_List) &&
          //     this.CPTType_List.length > 0
          //   ) {
          //     let cptindex = this.CPTType_List.findIndex(
          //       (x) => x.value == this.singleselection[0].procedureType
          //     );
          //     if (cptindex >= 0) {
          //       this.selectedProcedure = this.CPTType_List[cptindex].value;
          //     }
          //   }
          // }


          if (this.bIsMonth) {
            if (!isNullOrUndefined(this.MonthList) && this.MonthList.length) {
              if (
                !isNullOrUndefined(this.singleselection[0].month) &&
                this.singleselection[0].month != ""
              ) {
                this.selectedMonth = [];
                let LastSelectedMonth = this.singleselection[0].month.split(
                  ";"
                );
                for (let i = 0; i < LastSelectedMonth.length; i++) {
                  let monthindex = this.MonthList.findIndex(
                    (x) => x.text == LastSelectedMonth[i]
                  );
                  if (monthindex >= 0) {
                    this.selectedMonth.push(this.MonthList[monthindex]);
                  }
                }
              }
            }
          }
        }
      } else {
        if (
          !isNullOrUndefined(this.multipleselection) &&
          this.multipleselection.length > 0
        ) {
          if (this.bIsGroup) {
            if (
              !isNullOrUndefined(this.GroupList) &&
              this.GroupList.length > 0
            ) {
              let groupindex = this.GroupList.findIndex(
                (x) => x.groupid == this.multipleselection[0].parentGroupID
              );
              if (groupindex >= 0) {
                this.selectedGroup = this.GroupList[groupindex];
              }
            }
          }
          if (this.bIsPractice) {
            if (!isNullOrUndefined(this.PracticeList) && this.PracticeList.length > 0) {
              this.selectedPractice = [];
              let LastSelectedPractice = this.multipleselection[0].groupcode.split(";");
              for (let i = 0; i < LastSelectedPractice.length; i++) {
                let practiceindex = this.PracticeList.findIndex(
                  (x) => x.groupcode == LastSelectedPractice[i]
                );

                if (practiceindex >= 0) {
                  this.selectedPractice.push(this.PracticeList[practiceindex]);
                }
              }
              if (this.selectedPractice.length == 0) {
                for (let i = 0; i < this.PracticeList.length; i++) {
                  if (this.PracticeList[i].isparent == true) {
                    this.selectedPractice.push(this.PracticeList[i]);
                  }
                }
                if (this.selectedPractice.length == 0) {
                  for (let i = 0; i < this.PracticeList.length; i++) {
                    if (this.PracticeList[i].isparent == false && this.PracticeList[i].groupcode == "All") {
                      this.selectedPractice.push(this.PracticeList[i]);
                      break;
                    } else {
                      this.selectedPractice.push(this.PracticeList[i]);
                    }
                  }
                }
              }
              if (this.bIsPracticeProvider) {
                this.PracticeProviderList = [];
                this.providerkeyList = [];
                for (let i = 0; i < this.selectedPractice.length; i++) {
                  this.providerkeyList.push(this.selectedPractice[i].groupcode);
                  // this.getProviderslist(this.selectedPractice[i].groupcode);
                }
                if (!(this.bisProviderdash)) {
                  this.getProviderslist(this.providerkeyList);
                }

              }
            }
          }

          if (this.bIsMonthInterval && this.bisProviderdash) {
            if (
              !isNullOrUndefined(this.MonthIntervalList) &&
              this.MonthIntervalList.length > 0
            ) {
              let monthindex = this.MonthIntervalList.findIndex(
                (x) => x.value == this.singleselection[0].monthInterval
              );
              if (monthindex > 0) {
                this.selectedMonthInterval = this.MonthIntervalList[monthindex];
              }
            }
          }

          //  // 25 months for Keymetrics
          if (this.bIsMonthIntervalKM) {
            if (
              !isNullOrUndefined(this.MonthIntervalListofKeyMetrics) &&
              this.MonthIntervalListofKeyMetrics.length > 0
            ) {
              let monthindex = this.MonthIntervalListofKeyMetrics.findIndex(
                (x) => x.value == this.multipleselection[0].monthInterval
              );
              if (monthindex > 0) {
                this.selectedMonthIntervalKM = this.MonthIntervalListofKeyMetrics[monthindex];
              }
            }
          }

          if (this.bIsYear) {
            if (!isNullOrUndefined(this.YearList) && this.YearList.length > 0) {
              let yearindex = this.YearList.findIndex(
                (x) => x.value == this.multipleselection[0].year
              );
              if (yearindex > 0) {
                this.selectedYear = this.YearList[yearindex];
              }
            }
          }

          if (this.bIsProvider) {
            if (
              !isNullOrUndefined(this.ProviderList) &&
              this.ProviderList.length > 0
            ) {
              let providerindex = this.ProviderList.findIndex(
                (x) => x.value == this.multipleselection[0].providerType
              );
              if (providerindex >= 0) {
                this.selectedProvider = this.ProviderList[providerindex].value;
              }
            }
          }

          if (this.bIsProceduretype) { //CPT Type Filter of Provider Productivity -multiselection
            if (
              !isNullOrUndefined(this.CPTType_List) &&
              this.CPTType_List.length > 0
            ) {
              let cptindex = this.CPTType_List.findIndex(
                (x) => x.value == this.multipleselection[0].procedureType
              );
              if (cptindex >= 0) {
                this.selectedProcedure = this.CPTType_List[cptindex].value;
              }
            }
          }


          if (this.bIsMonth) {
            this.selectedMonth = [];
            if (!isNullOrUndefined(this.MonthList) && this.MonthList.length) {
              if (isNullOrUndefined(this.multipleselection[0].month)) {
                this.multipleselection[0].month = "";
              }
              let LastSelectedMonth = this.multipleselection[0].month.split(
                ";"
              );
              for (let i = 0; i < LastSelectedMonth.length; i++) {
                let monthindex = this.MonthList.findIndex(
                  (x) => x.text == LastSelectedMonth[i]
                );
                if (monthindex >= 0) {
                  if (this.bIsSingleMonth) {
                    this.selectedMonth = this.MonthList[monthindex];
                  } else {
                    this.selectedMonth.push(this.MonthList[monthindex]);
                  }
                }
              }
            }
          }
        }
      }

      //if condition when we come from practices dashboard
      if (!(this.selectedClientKey == '')) {
        for (let i = 0; i < this.PracticeList.length; i++) {
          if (this.selectedClientKey == this.PracticeList[i].groupcode) {
            this.selectedPractice = [];
            this.selectedPractice.push(this.PracticeList[i]);
          }
        }
      }


      // else {
      //   if (sessionStorage.getItem("ListofPractice")) {
      //     this.PracticeList = JSON.parse(sessionStorage.getItem("ListofPractice"));
      //     this.PracticeList.sort((a, b) => a.groupname.localeCompare(b.groupname))
      //     for (let i = 0; i < this.PracticeList.length; i++) {
      //       let Clientkey = this.PracticeList[i].groupcode;
      //       if (this.selectedClientKey == Clientkey) {
      //         this.selectedPractice = [];
      //         this.selectedPractice.push(this.PracticeList[i]);
      //       }
      //     }
      //   }
      // }
      setTimeout(() => {
        this.btnApplyFilter_Click('onload');
      }, 3000);


    } catch (error) {
      this.clsUtility.LogError(error);
      // console.log('sss1',error);
    }
  }

  SelectGroup($event: any) {
    try {
      let sGroup = $event;
      if (!isNullOrUndefined(sGroup) && sGroup != "") {
        this.filterdata.GetUserPractices(sGroup.groupid);
      } else {
        // this.selectedGroup = [];
        if (!isNullOrUndefined(this.GroupList)) {
          let index = this.GroupList.findIndex((x) => x.groupid == 0);
          if (index >= 0) {
            sGroup = this.GroupList[index];
            this.selectedGroup = this.GroupList[index];
          } else if (this.GroupList.length == 1) {
            sGroup = this.GroupList[0];
            this.selectedGroup = this.GroupList[0];
          }
        }
      }

      if (!isNullOrUndefined(sGroup)) {
        let index = this.PracticeList.findIndex(
          (x) => x.groupid == sGroup.groupid
        );
        if (!this.bIsSinglePractice) {
          if (index >= 0) {
            this.selectedPractice = [];

            this.selectedPractice.push(this.PracticeList[index]);
            this.IsselectedPractice = false;
          } else {
            this.IsselectedPractice = true;
          }
        } else if (this.bIsSinglePractice) {
          this.selectedPractice = this.PracticeList[index];
          this.IsselectedPractice = false;
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  selectPractice(practice) {
    ;
    try {
      if (practice.length >= 1) {
        if (practice[0].isparent == true || practice[0].groupcode == "All") {
          this.bIsOnHold = false;
        } else {
          this.bIsOnHold = true;
        }
        this.IsselectedPractice = false;
      } else {
        this.bIsOnHold = false;
        this.IsselectedPractice = true;
      }
      if (this.bIsPracticeProvider) {
        let clientkey: any;
        clientkey = [];
        this.PracticeProviderList = [];

        if (!isNullOrUndefined(practice)) {
          if (!this.bIsSinglePractice) {
            if (practice.groupcode === "All") {
              this.PracticeProviderList = [];
              this.providerkeyList = [];
              for (let i = 0; i < this.ListPractices.length; i++) {
                this.providerkeyList.push(this.ListPractices[i].groupcode);
                //this.getProviderslist(this.providerkeyList);
              }
              if (!(this.bisProviderdash)) {
                this.getProviderslist(this.providerkeyList);
              }
            } else {
              this.PracticeProviderList = [];
              this.providerkeyList = [];
              for (let i = 0; i < practice.length; i++) {
                this.providerkeyList.push(practice[i].groupcode);
                //this.getProviderslist(this.providerkeyList);
              }
              if (!(this.bisProviderdash)) {
                this.getProviderslist(this.providerkeyList);
              }
            }
          } else {
            clientkey.push(practice.groupcode);
          }
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  //Single practice function
  selectPracticenew(practice) {
    try {
      //debugger;
      this.selectedCPTName = [];
      this.selectedProviderName = practice;
      this.selectedPractice = practice; //for filter -when procedure type change practice -should updated to selected one
      if (practice.length == 1) {
        if (practice[0].isparent == true || practice[0].groupcode == "All") {
          this.bIsOnHold = false;
        } else {
          this.bIsOnHold = true;
        }
        //this.IsselectedPractice=false; 
      } else {
        this.bIsOnHold = false;
        // this.IsselectedPractice=true;
      }

      if (this.sNPI_id == 'null' || this.sNPI_id == undefined || this.sNPI_id == "") {
        this.getProviderslistNew(practice.groupcode);
        this.getCPTlistNew(practice.groupcode, this.selectedProcedure);
      } else {
        //this.getProviderslistNPI(practice.groupcode);
        this.getCPTlistNPI(practice.groupcode, this.selectedProcedure);
      }


    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  selectProvidernew(provider) {
    try {
      this.ProviderId = provider.nproviderid;
      //this.getPracticelistNPI(this.ProviderId);
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }


  //Provider New function -No NPI then normal dashboard will be shown to triarq user
  public getProviderslistNew(keyparam) {

    this.bisNPI = false;
    try {
      let providerparam: { clientkey: string; envname: string } = {
        clientkey: "",
        envname: "",
      };
      this.PracticeProviderList = [];
      providerparam.clientkey = keyparam;
      providerparam.envname = this.CurrentDataset;
      this.api.getFunctionName("metricsProviderNameNew");
      const providerftr = this.api.BindDataFMetrics(providerparam).share();
      providerftr
        .map((res) => res.json())
        .subscribe((res) => {
          if (res.length != 0) {
            for (let i = 0; i < res.length; i++) {
              this.PracticeProviderList.push({
                nproviderid: res[i].nproviderid,
                RenderingProvidersFullName: res[i].RenderingProvidersFullName,
                snpi: res[i].snpi,
              });
            }
            this.selectedProviderName = this.PracticeProviderList[0].RenderingProvidersFullName;
          }
          if (!isNullOrUndefined(this.PracticeProviderList)) { //If Normal user then no need to compare with NPI

            //let npi = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("sNPI")).toString();
            if (this.userType == "TRIARQ User") {
              //let providerlist = this.PracticeProviderList;
              // for (let i = 0; i < providerlist.length; i++) {
              //   let index = providerlist.findIndex((x) => x.snpi == npi);
              //   if (index >= 0) {
              //     this.PracticeProviderList = [];
              //     this.PracticeProviderList.push({
              //       nproviderid: providerlist[index].nproviderid,
              //       RenderingProvidersFullName: providerlist[index].RenderingProvidersFullName,
              //       snpi: providerlist[index].snpi,
              //     });
              //   }
              // }
              this.selectedProviderName = this.PracticeProviderList[0];
            } else {
              this.selectedProviderName = res[0];
            }
          }
          else {
            this.selectedProviderName = [];
            //console.log("hello")
          };
        });
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  //Provider New function -With NPI
  public getProviderslistNPI(keyparam) {
    this.bisNPI = true;
    this.disabled = true;
    try {
      let providerparam: { clientkey: string; envname: string, npi_id: string } = {
        clientkey: "",
        envname: "",
        npi_id: "",
        //nproviderid:""
      };
      this.PracticeProviderList = [];
      //this.PracticeList=[];
      providerparam.clientkey = keyparam;
      providerparam.envname = this.CurrentDataset;
      providerparam.npi_id = this.sNPI_id;
      //providerparam.nproviderid=this.ProviderId;
      this.api.getFunctionName("PracticeProviderNameLIstNPI");
      const providerftr = this.api.BindDataFMetrics(providerparam).share();
      providerftr
        .map((res) => res.json())
        .subscribe((res) => {
          if (res.length != 0) {
            for (let i = 0; i < res.length; i++) {
              this.PracticeProviderList.push({
                nproviderid: res[i].nproviderid,
                RenderingProvidersFullName: res[i].RenderingProvidersFullName,
                snpi: res[i].snpi,
                groupname: res[i].clinicname,
                groupcode: res[i].clientkey,
              });
              // this.PracticeListNPI.push({
              //   nproviderid: res[i].nproviderid,
              //   RenderingProvidersFullName: res[i].RenderingProvidersFullName,
              //   snpi: res[i].snpi,
              //   groupname: res[i].clinicname,
              //   groupcode: res[i].clientkey,
              // });
            }
            //console.log('PracticeList', this.PracticeList);
            res.forEach(item => {
              this.PracticeList.forEach(item1 => {  //Compare PracticeList get from session and res set of practices having same NPI
                if (item.clientkey === item1.groupcode) {
                  this.PracticeListNPI.push({
                    RenderingProvidersFullName: item.RenderingProvidersFullName,
                    snpi: item.snpi,
                    groupcode: item.clientkey,
                    groupname: item.clinicname
                  });
                }
              });
            });
            this.selectedProviderName = this.PracticeProviderList[0].RenderingProvidersFullName;
            this.SelectedPractice = this.PracticeListNPI[0].groupname;
          }
          if (!isNullOrUndefined(this.PracticeProviderList)) {
            let npi = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("sNPI")).toString();
            if (this.userType == "Provider") {
              let providerlist = this.PracticeProviderList; //push only list having access from myqone
              for (let i = 0; i < providerlist.length; i++) {
                let index = providerlist.findIndex((x) => x.snpi == npi);
                if (index >= 0) {
                  this.PracticeProviderList = [];
                  this.PracticeProviderList.push({
                    RenderingProvidersFullName: providerlist[index].RenderingProvidersFullName,
                    snpi: providerlist[index].snpi,
                    nproviderid: providerlist[index].nproviderid,
                    groupcode: providerlist[index].groupcode,
                    groupname: providerlist[index].groupname
                  });
                }
              }
              this.selectedProviderName = this.PracticeProviderList[0];
              //this.SelectedPractice = this.PracticeProviderList[0];
              this.SelectedPractice = this.PracticeListNPI[0];
            }
            else {
              this.selectedProviderName = res[0];
              this.SelectedPractice = res[0];
            }
          } else {
            this.selectedProviderName = [];
            this.SelectedPractice = [];
            this.bIsLoader = false;
          };
          //this.getPracticelistNPI();
        });
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }



  SelectMonth($event: any) {
    try {
      let sMonth = $event;
      let m: any;

      if (!isNullOrUndefined(sMonth) && sMonth.length > 0) {
        m = [];
        for (let i = 0; i < sMonth.length; i++) {
          m.push(sMonth[i].text);
        }
        this.isMonthSelected=false ; //change1
      }
      else{
        this.isMonthSelected=true;   //change1

      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  selectedComparison($event: any) {   //select which comparison what month or year
    try {
      //debugger;
      let sGroup = $event;
      if (!isNullOrUndefined(sGroup) && sGroup != "") {
        if (!isNullOrUndefined(this.CompareList)) {

          if (this.selectedTypeofCompare.value == "yearwise") {
            this.bIstrue = true;
            this.bIsMonthIntervalKM = false;
          }
          else {
            this.bIstrue = false;
            this.bIsMonthIntervalKM = true;
          }
        }
      }


    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }





  RetriveFilterData(): any {
    try {

      if (this.bIsGroup) {
        this.GroupList = this.filterdata.GetUserGroups();
        if (!isNullOrUndefined(this.GroupList) && this.GroupList.length > 0) {
          this.selectedGroup = this.GroupList[0];
        } else {
          this.bIsGroup = false;
        }
      } else {
        this.bIsGroup = false;
      }

      if (this.bIsComparision) {  //default filter selection for  Compare
        if (!isNullOrUndefined(this.CompareList) && this.CompareList.length > 0) {
          this.selectedTypeofCompare = this.CompareList[0];
        } else {
          this.bIsComparision = false;
        }
      } else {
        this.bIsComparision = false;
      }

      if (this.bIsPractice) {
        this.PracticeList = this.filterdata.GetUserPractices();
        if (
          !isNullOrUndefined(this.PracticeList) &&
          this.PracticeList.length > 0
        ) {
          if (!this.bIsGroup) {
            this.GroupList = this.filterdata.GetUserGroups();
            if (
              !isNullOrUndefined(this.GroupList) &&
              this.GroupList.length > 0
            ) {
              for (let i = 0; i < this.GroupList.length; i++) {
                let gindex = this.PracticeList.findIndex(
                  (x) => x.groupid == this.GroupList[i].groupid
                );
                if (gindex >= 0) {
                  this.PracticeList.splice(gindex, 1);
                }
              }
              this.GroupList = null;
            }
          }

          if (!this.bIsSinglePractice) {
            if (
              !isNullOrUndefined(this.GroupList) &&
              this.GroupList.length == 1
            ) {
              let index = this.PracticeList.findIndex((x) => x.groupid == 0);
              if (index >= 0) {
                this.PracticeList.splice(index, 1);
              }
            }

            this.selectedPractice = [];
            if (this.PracticeList.length == 1) {
              this.selectedPractice.push(this.PracticeList[0]);
            } else {
              this.SelectGroup(this.selectedGroup);
            }
          } else if (this.bIsSinglePractice) {
            if (this.PracticeList.length == 1) {
              this.selectedPractice = this.PracticeList[0];
            } else {
              let index = this.PracticeList.findIndex((x) => x.groupid == 0);
              if (index >= 0) {
                this.PracticeList.splice(index, 1);
              }
              this.selectedPractice = this.PracticeList[0];
            }

            // console.log('list of practice',this.PracticeList )
          }
        } else {
          this.bIsPractice = false;
        }
      }
      if (this.bIsMinDays) {
        this.selectedMinDays = 15;
      }
      if (this.bIsMaxDays) {
        this.selectedMaxDays = 30;
      }

      if (this.bIsStatus) {
        if (isNullOrUndefined(this.selectedStatus)) {
          this.selectedStatus = this.statusList[1];
        }
      }

      //12 months for Provider productivity
      if (this.bIsMonthInterval && this.bisProviderdash) {
        let index = this.MonthIntervalList.findIndex((x) => x.value == "6");
        if (index > 0) {
          this.selectedMonthInterval = this.MonthIntervalList[index];
        }
      }

      // // 25 months for Keymetrics
      if (this.bIsMonthIntervalKM) {
        let index = this.MonthIntervalListofKeyMetrics.findIndex((x) => x.value == "6");
        if (index > 0) {
          this.selectedMonthIntervalKM = this.MonthIntervalListofKeyMetrics[index];
        }
      }

      if (this.bIsPracticeProvider) {
        this.providerkeyList = [];
        if (!(isNullOrUndefined(this.selectedPractice))) {
          for (let i = 0; i < this.selectedPractice.length; i++) {
            this.providerkeyList.push(this.selectedPractice[i].groupcode);
            // this.getProviderslist(this.selectedPractice[i].groupcode);
          }
          if (!(this.bisProviderdash)) {
            this.getProviderslist(this.providerkeyList);
          }
        } else {
          this._router.navigate(["qdashboards/AppAccess"])
        }

      }

      if (this.bIsYear) {
        this.YearList = [];
        let year: { text: string; value: string } = { text: "", value: "" };
        for (let i = 0; i < 5; i++) {
          year.text = (new Date().getFullYear() - i).toString();
          year.value = (new Date().getFullYear() - i).toString();
          this.YearList.push({ ...year });
        }
        this.selectedYear = this.YearList[0];
      }

      if (this.bIsStartDate) {
        this.selectedStartDate = this.dateConvert(this.StartdateValue);
      }

      if (this.bIsEndDate) {
        this.selectedEndDate = this.dateConvert(this.EnddateValue);
      }

      /*   if (this.bIsMinDays) {
        this.selectedMinDays = Number(this.selectedMinDays);
      }
      if (this.bIsMaxDays) {
        this.selectedMaxDays = Number(this.selectedMaxDays);
      } */
      if (this.bIsProvider) {
        let index = this.ProviderList.findIndex(
          (x) => x.value == "Billing Provider"
        );
        if (index >= 0) {
          this.selectedProvider = this.ProviderList[index].value;
        }
      }

      if (this.bIsProceduretype) {
        let index = this.CPTType_List.findIndex(
          (x) => x.value == "cptcode"
        );
        if (index >= 0) {
          this.selectedProcedure = this.CPTType_List[index].value;
        }
      }


      if (this.bIsMonth) {
        this.selectedMonth = [];
        for (let i = 0; i < 3; i++) {
          let nmonth = new Date().getMonth() - i;
          let index = this.MonthList.findIndex(
            (x) => x.value == nmonth.toString()
          );
          if (index >= 0) {
            this.selectedMonth.push(this.MonthList[index]);
          }
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  validateFilter() {
    try {
      // if (
      //   this.bIsGroup &&
      //   (isNullOrUndefined(this.selectedGroup) ||
      //     this.selectedGroup.length <= 0)
      // ) {
      //   this.clsUtility.showWarning("Please Select Group");
      //   return false;
      // }
      // if (
      //   this.bIsPractice &&
      //   (isNullOrUndefined(this.selectedPractice) ||
      //     this.selectedPractice.length <= 0)
      // ) {
      //   this.clsUtility.showWarning("Please Select Practice");
      //   return false;
      // }
      // if (
      //   this.bIsMonthInterval &&
      //   (isNullOrUndefined(this.selectedMonthInterval) ||
      //     this.selectedMonthInterval.length <= 0)
      // ) {
      //   this.clsUtility.showWarning("Please Select Interval");
      //   return false;
      // }
      /*  if (
        this.bIsMonth &&
        (isNullOrUndefined(this.selectedMonth) ||
          this.selectedMonth.length <= 0)
      ) {
        this.clsUtility.showWarning("Please Select Month");
        return false;
      } */
      return true;
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  ApplyFilter(data: any) {
    this.FilterData.emit(data);
  }

  btnApplyFilter_Click(event) {
    try {

      let CPT = "";
      if (this.selectedCPTName.length > 0) {
        let CPT_SelectedItem = "";
        this.selectedCPTName.forEach((obj) => {
          if (CPT_SelectedItem == "") {
            CPT_SelectedItem = "['" + obj.itemName + "'";
          }
          else {
            CPT_SelectedItem = CPT_SelectedItem + ",'" + obj.itemName + "'";
          }
        });
        CPT = CPT_SelectedItem + "]";
      }
      this.D_selectedCPTName = this.selectedCPTName;

      if (this.validateFilter()) {
        this.DisplaySelectedPractice = [];
        this.DisplaySelectedPractice = this.selectedPractice;

        let Practices = [];
        let practicename = [];
        let DisplayPractices = [];
        let Month = [];
        let providername;
        let snpi: any;
        let providerId: any;
        let mindays: any;
        let maxdays: any;
        let status: any;
        let procedureValue: any;
        let procedureType: any;
        let providertype: any;

        //  comment for  empty group selection
        // if (!isNullOrUndefined(this.selectedGroup)) {
        //   for (let i = 0; i < this.ListPractices.length; i++) {
        //     if (
        //       this.ListPractices[i].parentgroupid == this.selectedGroup.groupid
        //     ) {
        //       Practices.push(this.ListPractices[i].groupcode);
        //     }
        //   }
        // }


        //debugger;
        if (this.bisNPI == false) { ///without NPI
          if (!isNullOrUndefined(this.selectedPractice)) {
            if (this.selectedPractice.length > 0) {
              for (let i = 0; i < this.selectedPractice.length; i++) {
                if (this.selectedPractice[i].isparent) {
                  for (let j = 0; j < this.ListPractices.length; j++) {
                    if (
                      this.ListPractices[j].parentgroupid ==
                      this.selectedPractice[i].groupid
                    ) {
                      //debugger;
                      Practices.push(this.ListPractices[j].groupcode);
                    }
                  }
                }

                if (this.selectedPractice[i].groupid == 0) {
                  if (!this.selectedPractice[i].isparent) {
                    for (let j = 0; j < this.PracticeList.length; j++) {
                      if (
                        !this.PracticeList[j].isparent &&
                        this.PracticeList[j].groupid != 0
                      ) {
                        Practices.push(this.PracticeList[j].groupcode);
                        practicename.push(this.PracticeList[j].groupname);
                      }
                    }
                  }
                }

                if (!this.selectedPractice[i].isparent) {
                  DisplayPractices.push(this.selectedPractice[i]);
                  let index = Practices.findIndex(
                    (x) => x == this.selectedPractice[i].groupcode
                  );
                  if (index < 0 && this.selectedPractice[i].groupid != 0) {
                    Practices.push(this.selectedPractice[i].groupcode);
                    practicename.push(this.selectedPractice[i].groupname);
                  }
                } else {
                  DisplayPractices.push(this.selectedPractice[i]);
                }
              }
            } else {
              DisplayPractices.push(this.selectedPractice);
              Practices.push(this.selectedPractice.groupcode);
              practicename.push(this.selectedPractice.groupname);
            }
          }
        }
        else { ///with NPI
          if (!isNullOrUndefined(this.SelectedPractice)) {
            if (this.SelectedPractice.length > 0) {
              for (let i = 0; i < this.SelectedPractice.length; i++) {

                if (!this.SelectedPractice[i].isparent) {
                  DisplayPractices.push(this.SelectedPractice[i]);
                  let index = Practices.findIndex(
                    (x) => x == this.SelectedPractice[i].groupcode
                  );
                  if (index < 0 && this.SelectedPractice[i].groupid != 0) {
                    Practices.push(this.SelectedPractice[i].groupcode);
                    practicename.push(this.SelectedPractice[i].groupname);
                  }
                } else {
                  DisplayPractices.push(this.SelectedPractice[i]);
                }
              }
            } else {
              DisplayPractices.push(this.SelectedPractice);
              Practices.push(this.SelectedPractice.groupcode);
              practicename.push(this.SelectedPractice.groupname);
            }
          }
          if (isNullOrUndefined(this.SelectedPractice)) {
            this.clsUtility.showWarning("There is no data for this NPI");
          }

        }

        ////debugger;
        if (
          !isNullOrUndefined(this.selectedGroup) &&
          !isNullOrUndefined(this.selectedPractice)
        ) {
          // for (let i = 0; i < this.selectedGroup.length; i++) {
          if (!this.bIsSinglePractice) {
            let index = this.selectedPractice.findIndex(
              (x) => x.groupid == this.selectedGroup.groupid
            );
            let dindex = DisplayPractices.findIndex(
              (x) => x.groupid == this.selectedGroup.groupid
            );

            if (index >= 0 && dindex < 0) {
              DisplayPractices.push(this.selectedPractice[index]);
            }
          } else if (this.bIsSinglePractice) {
            DisplayPractices.push(this.selectedPractice);
          }
          // }
        }

        if (!isNullOrUndefined(this.selectedMonth)) {
          if (this.bIsSingleMonth) {
            this.hidebutton=false; //change1
            if (!isNullOrUndefined(this.selectedMonth)) {
              Month.push(this.selectedMonth.text);
            } else {
              this.selectedMonth = [];
              this.selectedMonth.push(this.MonthList[0]);

              Month.push(this.selectedMonth.text);
            }
          }
          if (!this.bIsSingleMonth && this.selectedMonth.length > 0) {
            this.hidebutton=true;   //change1
            for (let i = 0; i < this.selectedMonth.length; i++) {
              Month.push(this.selectedMonth[i].text);
            }
          }
        }

        if (this.bIsPracticeProvider) {

          if (!isNullOrUndefined(this.selectedProviderName)) {
            providername = this.selectedProviderName.RenderingProvidersFullName;
            snpi = this.selectedProviderName.snpi;
            providerId = this.selectedProviderName.nproviderid;
          } else {
            if (
              !isNullOrUndefined(this.PracticeProviderList) &&
              this.PracticeProviderList.length != 0
            ) {
              providername = this.PracticeProviderList[0].RenderingProvidersFullName;
              snpi = this.PracticeProviderList[0].snpi;
              providerId = this.PracticeProviderList[0].nproviderid;
            } else {
              providername = "";
              snpi = "";
              providerId = "";
            }
          }
        }



        if (this.bIsStartDate) {
          this.selectedStartDate = this.dateConvert(this.StartdateValue);
        }

        if (this.bIsEndDate) {
          this.selectedEndDate = this.dateConvert(this.EnddateValue);
        }

        if (this.bIsMinDays) {
          mindays = this.selectedMinDays;
        }

        if (this.bIsMaxDays) {
          maxdays = this.selectedMaxDays;
        }


        if (this.bIsProcedureval) {
          procedureValue = CPT;
        }

        if (this.bIsProceduretype) {
          procedureType = this.selectedProcedure;
        }

        if (this.bIsProvider) {
          providertype = this.selectedProvider;
        }




        if (this.bIsStatus) {
          if (!isNullOrUndefined(this.selectedStatus)) {
            status = this.selectedStatus["value"];
          } else {
            status = "";
          }
        }

        /*    if (this.bIsPracticeProvider) {
          if (isNullOrUndefined(this.selectedProviderName)) {
            this.selectedProviderName = this.PracticeProviderList[0];
            providername = this.selectedProviderName.ProviderFullName;
            snpi = this.selectedProviderName.snpi;
            if (!isNullOrUndefined(this.PracticeProviderList)) {
              providername = this.selectedProviderName.ProviderFullName;
              snpi = this.selectedProviderName.snpi;
            } else {
              providername = "";
              snpi = "";
            }
          }
        } */

        function GetSortOrder(prop) {
          return function (a, b) {
            if (a[prop] > b[prop]) {
              return 1;
            } else if (a[prop] < b[prop]) {
              return -1;
            }
            return 0;
          };
        }
        DisplayPractices.sort(GetSortOrder("groupname"));
        // debugger;
        this.AllFilterJSON = new OutputFilter();
        this.AllFilterJSON.group = this.bIsGroup ? this.selectedGroup : [];
        this.AllFilterJSON.practice = this.bIsPractice ? Practices : [];
        this.AllFilterJSON.practicename = this.bIsPractice ? practicename[0] : "";
        this.AllFilterJSON.ProviderName = this.bIsPracticeProvider
          ? providername
          : "";
        this.AllFilterJSON.nproviderid = this.bIsPracticeProvider
          ? providerId
          : "";
        this.AllFilterJSON.sNpi = this.bIsPracticeProvider ? snpi : "";
        this.AllFilterJSON.monthinterval = this.bIsMonthInterval ? this.selectedMonthInterval.value : "";  //12 month interval 
        this.AllFilterJSON.monthintervalKM = this.bIsMonthIntervalKM ? this.selectedMonthIntervalKM.value : "";  //25 month interval -KM
        this.AllFilterJSON.displaypractice = DisplayPractices;
        this.AllFilterJSON.year = this.bIsYear ? this.selectedYear.value : "";

        this.AllFilterJSON.provider = this.bIsProvider ? providertype : "";

        this.AllFilterJSON.proceduretype = this.bIsProceduretype ? procedureType : ""; //procedureType filter in Provider prod
        this.AllFilterJSON.procedurevalue = this.bIsProcedureval ? procedureValue : ""; //procedure filter in Provider prod
        this.AllFilterJSON.month = this.bIsMonth ? Month : [];  //Array of month
        this.AllFilterJSON.singlemonth = this.bIsSingleMonth ? this.selectedMonth.value : ""; //single month for KM
        this.AllFilterJSON.yearNumber = this.bIsyearNumber ? this.selectedYearNumber.value : 5; //last four year data
        this.AllFilterJSON.MonthOrYearWise = this.bIsComparision ? this.selectedTypeofCompare.text : "";
        this.AllFilterJSON.startdate = this.bIsStartDate
          ? this.selectedStartDate
          : "";
        this.AllFilterJSON.enddate = this.bIsEndDate
          ? this.selectedEndDate
          : ";";
        this.AllFilterJSON.MinDays = this.bIsMinDays ? mindays : 0;
        this.AllFilterJSON.MaxDays = this.bIsMaxDays ? maxdays : 0;
        this.AllFilterJSON.Status = this.bIsStatus
          ? this.selectedStatus["value"]
          : "";

        // if (event == 'onload') {
        var onholdData = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("BQPractices")));  //Decryption //JSON.parse(sessionStorage.getItem("BQPractices"))
        //console.log('BQPractices',onholdData)
        if (Practices.length == 1) {
          for (let i = 0; i < onholdData.length; i++) {
            if (onholdData[i].clientkey == Practices[0]) {
              this.Onholddata = onholdData[i].on_hold;
              this.OnholddataChecked = this.Onholddata;
              this.bIsOnHold = true;
            }
          }

        } else {
          this.Onholddata = "false";
          this.OnholddataChecked = false;
          this.bIsOnHold = false;
        }
        // }

        this.AllFilterJSON.onHold = this.Onholddata;
        this.AllFilterJSON.bIsOnHold = this.bIsOnHold;
        this.AllFilterJSON.OnholddataChecked = this.OnholddataChecked;
        //sessionStorage.setItem( "filterJSON", JSON.stringify(this.AllFilterJSON));  //old
        sessionStorage.setItem('filterJSON', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.AllFilterJSON))); //Encryption


        let a;
        a = [];
        let time = new Date();
        let preservefilterip = new PreserveFilterInput();
        if (this.bisNPI == false) {
          if (sessionStorage.getItem("AllSelection")) {
            a = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("AllSelection"))); //Decryption  //JSON.parse(sessionStorage.getItem("AllSelection"));
          }

          preservefilterip.userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid"));
          preservefilterip.parentGroupID = this.bIsGroup
            ? this.selectedGroup.groupid.toString()
            : "0";
          preservefilterip.parentGroupName = this.bIsGroup
            ? this.selectedGroup.groupname.toString()
            : "All";
          if (this.bIsPractice && !this.bIsSinglePractice) {
            let groupname, groupcode;
            groupname = [];
            groupcode = [];

            for (let i = 0; i < this.selectedPractice.length; i++) {
              groupname.push(this.selectedPractice[i].groupname);
              groupcode.push(this.selectedPractice[i].groupcode);
            }
            preservefilterip.groupname = groupname;
            preservefilterip.groupcode = groupcode;
          } else if (this.bIsPractice && this.bIsSinglePractice) {
            if (
              !isNullOrUndefined(this.selectedPractice)
              //&& this.selectedPractice.length > 0
            ) {
              // if (this.selectedPractice.length == 1) {
              //   if ("i==1")
              preservefilterip.groupname = this.selectedPractice.groupname;
              preservefilterip.groupcode = this.selectedPractice.groupcode;
              // } else {
              //   if ("else i==1")
              //     for (let i = 0; i < this.selectedPractice; i++) {
              //       preservefilterip.groupname = this.selectedPractice[
              //         i
              //       ].groupname;
              //       preservefilterip.groupcode = this.selectedPractice[
              //         i
              //       ].groupcode;
              //     }
              // }
            } else {
              // for (let i = 0; i < this.selectedPractice; i++) {
              //   preservefilterip.groupname = this.selectedPractice[i].groupname;
              //   preservefilterip.groupcode = this.selectedPractice[i].groupcode;
              // }
              preservefilterip.groupname = a.groupname;
              preservefilterip.groupcode = a.groupcode;
            }
          }

        } else {
          if (sessionStorage.getItem("UserLastSelection")) {
            // a = JSON.parse(sessionStorage.getItem("UserLastSelection"));
            a = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("UserLastSelection")))  //Decryption
          }

          if (this.bIsPractice && this.bIsSinglePractice) {
            if (!isNullOrUndefined(this.selectedPractice)) {
              preservefilterip.groupname = this.selectedPractice.groupname;
              preservefilterip.groupcode = this.selectedPractice.groupcode;
            }
            else {
              preservefilterip.groupname = a.singleselection[0].groupname;
              preservefilterip.groupcode = a.singleselection[0].groupcode;
            }
          }


        }


        if (this.bIsMonth) {
          let month;
          month = [];

          for (let i = 0; i < this.selectedMonth.length; i++) {
            month.push(this.selectedMonth[i].text);
          }
          preservefilterip.month = month;
        }
        // else {
        //   if (!isNullOrUndefined(a.month)) {
        //     preservefilterip.month = a.month;
        //   } else {
        //     let month;
        //     month = [];
        //     for (let i = 0; i < 3; i++) {
        //       let nmonth = new Date().getMonth() - i;
        //       let index = this.MonthList.findIndex(
        //         (x) => x.value == nmonth.toString()
        //       );
        //       if (index >= 0) {
        //         month.push(this.MonthList[index].text);
        //       }
        //     }
        //     preservefilterip.month = month;
        //   }
        // }

        if (this.bIsProvider) {
          preservefilterip.providerType = this.selectedProvider;
        }

        if (this.bIsProceduretype) {  // CPT Type Filter Provider Prod
          preservefilterip.procedureType = this.selectedProcedure;
        }

        //12 months for Provider productivity
        if (this.bIsMonthInterval && this.bisProviderdash) {
          preservefilterip.monthInterval = this.selectedMonthInterval.value;
        }

        //  // 25 months for Keymetrics
        if (this.bIsMonthIntervalKM) {
          preservefilterip.monthInterval = this.selectedMonthIntervalKM.value;
        }


        // else {
        //   if (!isNullOrUndefined(a.monthInterval)) {
        //     preservefilterip.monthInterval = a.monthInterval;
        //   }
        // }
        if (this.bIsStartDate) {
          preservefilterip.StartDate = this.dateConvert(this.StartdateValue);
        }
        // else {
        //   if (!isNullOrUndefined(a.StartDate)) {
        //     preservefilterip.StartDate = a.StartDate;
        //   } else {
        //     preservefilterip.StartDate = this.dateConvert(this.StartdateValue);
        //   }
        // }
        if (this.bIsEndDate) {
          preservefilterip.endDate = this.dateConvert(this.EnddateValue);
        }
        // else {
        //   if (!isNullOrUndefined(a.endDate)) {
        //     preservefilterip.endDate = a.endDate;
        //   } else {
        //     preservefilterip.endDate = this.dateConvert(this.EnddateValue);
        //   }
        // }

        // this.YearList = [];
        // let year: { text: string; value: string } = { text: "", value: "" };
        // for (let i = 0; i < 5; i++) {
        //   year.text = (new Date().getFullYear() - i).toString();
        //   year.value = (new Date().getFullYear() - i).toString();
        //   this.YearList.push({ ...year });
        // }

        if (this.bIsYear) {
          preservefilterip.year = this.selectedYear.text;
        }
        // else {
        //   if (!isNullOrUndefined(a.year)) {
        //     preservefilterip.year = a.year;
        //   } else {
        //     preservefilterip.year = this.YearList[0].value;
        //   }
        // }

        preservefilterip.tran_id = time.toString();
        if (isNullOrUndefined(this.selectedPractice) ||
          this.selectedPractice.length <= 0 || this.selectedPractice == "") {
          this._router.navigate(["/qdashboards/AppAccess"]);
        } else {
          //sessionStorage.setItem("AllSelection",JSON.stringify(preservefilterip));
          sessionStorage.setItem('AllSelection', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(preservefilterip)));




          this.InsertUserSelection(preservefilterip);
          this.ApplyFilter(this.AllFilterJSON);
        }

        // console.log('AllFilterJSON',this.AllFilterJSON);
      }
    } catch (error) {
      this.clsUtility.LogError(error);
      this.clsUtility.showWarning("Please Select Practice");
      // console.log('ss2',error)
    }
  }

  async ClearFilter() {
    try {
      this.ClearControls();
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  dateConvert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/"); // mm-dd-yyyy
    // return [date.getFullYear(), mnth, day].join("-"); //yyyy-mm-dd
  }

  ClearControls(): any {
    try {
      this.selectedGroup = [];
      this.selectedPractice = [];
      this.selectedMonthInterval = [];
      this.selectedGroup = this.GroupList[0];
      this.DisplaySelectedPractice = [];

      let monthintervalindex = this.MonthIntervalList.findIndex(
        (x) => x.value == "6"
      );
      if (monthintervalindex > 0) {
        this.selectedMonthInterval = this.MonthIntervalList[monthintervalindex];
      }


      let monthintervalindex1 = this.MonthIntervalListofKeyMetrics.findIndex(
        (x) => x.value == "6"
      );
      if (monthintervalindex1 > 0) {
        this.selectedMonthIntervalKM = this.MonthIntervalListofKeyMetrics[monthintervalindex1];
      }





    } catch (error) {
      this.clsUtility.LogError(error);
      //  console.log("SS",error);
    }
  }

  InsertUserSelection(InsertInput) {
    try {
      // debugger;
      let preservefilterinput = new PreserveFilterInput();
      preservefilterinput.envname = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment")).toString(); //sessionStorage.getItem("currentEnvironment").toString();
      preservefilterinput.year = InsertInput.year;
      preservefilterinput.monthInterval = InsertInput.monthInterval;
      preservefilterinput.providerType = InsertInput.providerType;
      preservefilterinput.procedureType = InsertInput.procedureType;//provider prod DB
      preservefilterinput.StartDate = InsertInput.StartDate;
      preservefilterinput.endDate = InsertInput.endDate;
      preservefilterinput.tran_id = InsertInput.tran_id;
      preservefilterinput.userid = InsertInput.userid;
      preservefilterinput.parentGroupID = InsertInput.parentGroupID;
      preservefilterinput.parentGroupName = InsertInput.parentGroupName;
      preservefilterinput.PracticeSelectionType = this.bIsSinglePractice
        ? "Single"
        : "Multiple";
      // month

      if (this.bIsMonth) {
        let m = InsertInput.month[0];
        for (let i = 1; i < InsertInput.month.length; i++) {
          m = m + ";" + InsertInput.month[i];
        }
        preservefilterinput.month = m;
      }
      // month

      if (!this.bIsSinglePractice) {
        let gname = InsertInput.groupname[0];
        let gcode = InsertInput.groupcode[0];
        for (let i = 1; i < InsertInput.groupcode.length; i++) {
          gname = gname + ";" + InsertInput.groupname[i];
          gcode = gcode + ";" + InsertInput.groupcode[i];
        }
        preservefilterinput.groupname = gname;
        preservefilterinput.groupcode = gcode;
      } else {
        preservefilterinput.groupname = InsertInput.groupname;
        preservefilterinput.groupcode = InsertInput.groupcode;
      }
      this.api.getFunctionName("UserGroupInsert");
      const UserSelectionCall = this.api.BindDataFMetrics(preservefilterinput).share();
      this.subscription.add(
        UserSelectionCall.map((res) => res.json()).subscribe((res) => {
          this.filterdata.setUserLastSelection(res);
        })
      );
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  public getProviderslist(keyparam) {
    try {
      let providerparam: { clientkey: string[]; envname: string } = {
        clientkey: [],
        envname: "",
      };
      providerparam.clientkey = keyparam;
      providerparam.envname = this.CurrentDataset;
      this.api.getFunctionName("metricsProviderName");
      const providerftr = this.api.BindDataFMetrics(providerparam).share();
      providerftr
        .map((res) => res.json())
        .subscribe((res) => {
          if (res.length != 0) {
            for (let i = 0; i < res.length; i++) {
              /*  if (
                 !this.PracticeProviderList.some(
                   (item) => item.ProviderFullName == res[i].ProviderFullName
                 )
               ) */ //{
              this.PracticeProviderList.push({
                nproviderid: res[i].nproviderid,
                ProviderFullName: res[i].ProviderFullName,
                snpi: res[i].snpi,
              });
              //this.selectedProviderName = this.PracticeProviderList[0].ProviderFullName;
              //}
            }
            this.selectedProviderName = this.PracticeProviderList[0].ProviderFullName;
          }

          // this.PracticeProviderList.push(res);
          if (!isNullOrUndefined(this.PracticeProviderList)) {
            let userType = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertype")).toString();
            let npi = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("sNPI")).toString();
            if (userType == "Provider") {
              let providerlist = this.PracticeProviderList;
              for (let i = 0; i < providerlist.length; i++) {
                let index = providerlist.findIndex((x) => x.snpi == npi);
                if (index >= 0) {
                  this.PracticeProviderList = [];
                  this.PracticeProviderList.push({
                    nproviderid: providerlist[index].nproviderid,
                    ProviderFullName: providerlist[index].ProviderFullName,
                    snpi: providerlist[index].snpi,
                  });
                }
              }
              this.selectedProviderName = this.PracticeProviderList[0];
            } else {
              this.selectedProviderName = res[0];
            }
          } else {
            this.selectedProviderName = [];
            //     console.log("hii");
          }
        });
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  exludeOnChange(event) {
    if (event == true) {
      this.Onholddata = "true";
      // this.OnholddataChecked = true;
    }
    else {
      this.Onholddata = "false";
      // this.OnholddataChecked = false;
    }
  }


  CPTType_Change(Type: any) {
    //debugger;
    try {
      this.selectedCPTName = [];
      this.selectedProcedure = Type;
      let index = this.CPTType_List.findIndex(x => x.value == this.selectedProcedure);
      if (index >= 0) {
        this.CPT_TypeDisplay = this.CPTType_List[index].value;
      }
      this.bIsLoaderCptTypes = true;
      if (this.sNPI_id == 'null' || this.sNPI_id == undefined || this.sNPI_id == "") {
        this.getCPTlistNew(this.selectedPractice.groupcode, this.selectedProcedure);
      }
      else {
        this.getCPTlistNPI(this.selectedPractice.groupcode, this.selectedProcedure);
      }

    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }


  OnCPTSelect(event: any) {
    //debugger;
    try {
      // console.log("e",event);
      if (event != null && event != undefined) {
        let selecteditem = event;
        this.selectedCPT_Display.push(selecteditem.itemName);
        this.SelectedCPT_Badges = "";
        for (let i = 0; i < this.selectedCPT_Display.length; i++) {
          if (this.SelectedCPT_Badges == "") {
            this.SelectedCPT_Badges = this.selectedCPT_Display[i];
          } else {
            this.SelectedCPT_Badges = this.SelectedCPT_Badges + "," + this.selectedCPT_Display[i];
          }
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
      // console.log("e",error);
    }
  }
  OnCPTDeSelect(event: any) {
    try {
      if (event != null && event != undefined) {
        let selecteditem = event;
        let index = this.selectedCPT_Display.findIndex(x => x == selecteditem.itemName);
        if (index >= 0) {
          this.selectedCPT_Display.splice(index, 1);
        }
        this.SelectedCPT_Badges = "";
        for (let i = 0; i < this.selectedCPT_Display.length; i++) {
          if (this.SelectedCPT_Badges == "") {
            this.SelectedCPT_Badges = this.selectedCPT_Display[i];
          } else {
            this.SelectedCPT_Badges = this.SelectedCPT_Badges + "," + this.selectedCPT_Display[i];
          }
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }


  OnCPTSelectAll(event: any) {
    try {
      // debugger;
      console.log("OnCPTSelectAll event", event);
      if (event != null && event != undefined) {
        if (event.length > 0) {
          this.selectedCPT_Display = [];
          for (let i = 0; i < event.length; i++) {
            this.selectedCPT_Display.push(event[i].itemName);
          }
          this.SelectedCPT_Badges = "";
          for (let i = 0; i < this.selectedCPT_Display.length; i++) {
            if (this.SelectedCPT_Badges == "") {
              this.SelectedCPT_Badges = this.selectedCPT_Display[i];
            } else {
              this.SelectedCPT_Badges = this.SelectedCPT_Badges + "," + this.selectedCPT_Display[i];
            }
          }
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }
  OnCPTDeSelectAll(items: any) {
    try {
      this.selectedCPTName = [];
      this.selectedCPT_Display = [];
      this.SelectedCPT_Badges = "";
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }






  //CPT Filter-NO NPI
  public getCPTlistNew(keyparam, event) {
    try {
      let providerparam: { clientkey: string; envname: string; operationtype: string } = {
        clientkey: "",
        envname: "",
        operationtype: "",
        // provider_id:""
      };
      this.PracticeCPTList = [];
      providerparam.clientkey = keyparam;
      providerparam.envname = this.CurrentDataset;
      providerparam.operationtype = event;
      //providerparam.provider_id=this.provider_id;
      this.api.getFunctionName("metricsCPTNameNew");
      let seq = this.api.BindDataFMetrics(providerparam).share();
      this.subscription.add(
        seq.subscribe(
          (response) => {
            if (response != null && response != undefined) {
              let result: any = [];
              result = response;
              let res: any = JSON.parse(result._body);
              if (res != null && res != undefined && res.length > 0) {
                if (event == 'cptgroup') {
                  this.PracticeCPTList = [];
                  if (res.length > 0) {
                    res.forEach((obj) => {
                      let cptdata = {
                        id: obj.cptgroup,
                        itemName: obj.cptgroup,
                      };
                      this.PracticeCPTList.push(cptdata);
                    }
                    );
                    this.bIsLoaderCptTypes = false;
                  }
                  // this.selectedCPTName = this.PracticeCPTList[0].itemName;
                }
                else if (event == 'cptcode') {
                  for (let i = 0; i < res.length; i++) {
                    this.PracticeCPTList = [];
                    if (res.length > 0) {
                      res.forEach((obj) => {
                        let cptdata = {
                          id: obj.cptcode,
                          itemName: obj.cptcode,
                        };
                        this.PracticeCPTList.push(cptdata);
                      });
                    }
                    this.bIsLoaderCptTypes = false;
                    //this.selectedCPTName = this.PracticeCPTList[0].itemName;
                  }

                }
                else {
                  if (event == 'cptgroup' || event == 'cptcode') {
                    this.PracticeCPTList = [];
                    this.bIsLoaderCptTypes = false;
                  }
                }
              }
              this.bIsLoader = false;
            }
          },
          (err) => {
            this.bIsLoader = false;
            this.clsUtility.LogError(err);
          }
        )
      );
    } catch (error) {
      this.bIsLoader = false;
      this.clsUtility.LogError(error);
    }
  }
  //CPT Filter-With NPI
  public getCPTlistNPI(keyparam, event) {
    try {
      let providerparam: { clientkey: string; envname: string; operationtype: string, npi_id: string } = {
        clientkey: "",
        envname: "",
        operationtype: "",
        npi_id: ""
      };
      this.PracticeCPTList = [];
      providerparam.clientkey = keyparam;
      providerparam.envname = this.CurrentDataset;
      providerparam.operationtype = event;
      providerparam.npi_id = this.sNPI_id;
      this.api.getFunctionName("metricsCPTNameNPI");
      let seq = this.api.BindDataFMetrics(providerparam).share();
      this.subscription.add(
        seq.subscribe(
          (response) => {
            if (response != null && response != undefined) {
              let result: any = [];
              result = response;
              let res: any = JSON.parse(result._body);
              if (res != null && res != undefined && res.length > 0) {
                if (event == 'cptgroup') {
                  this.PracticeCPTList = [];
                  if (res.length > 0) {
                    res.forEach((obj) => {
                      let cptdata = {
                        id: obj.cptgroup,
                        itemName: obj.cptgroup,
                      };
                      this.PracticeCPTList.push(cptdata);
                    }
                    );
                    this.bIsLoaderCptTypes = false;
                  }
                  // this.selectedCPTName = this.PracticeCPTList[0].itemName;
                }
                else if (event == 'cptcode') {
                  for (let i = 0; i < res.length; i++) {
                    this.PracticeCPTList = [];
                    if (res.length > 0) {
                      res.forEach((obj) => {
                        let cptdata = {
                          id: obj.cptcode,
                          itemName: obj.cptcode,
                        };
                        this.PracticeCPTList.push(cptdata);
                      });
                    }
                    this.bIsLoaderCptTypes = false;
                    //this.selectedCPTName = this.PracticeCPTList[0].itemName;
                  }

                }
                else {
                  if (event == 'cptgroup' || event == 'scptcode') {
                    this.PracticeCPTList = [];
                    this.bIsLoaderCptTypes = false;
                  }
                }
              }
              this.bIsLoader = false;
            }
          },
          (err) => {
            this.bIsLoader = false;
            this.clsUtility.LogError(err);
          }
        )
      );
    } catch (error) {
      this.bIsLoader = false;
      this.clsUtility.LogError(error);
    }
  }

}
function isNullOrUndefined(value: any): boolean { return value === null || value === undefined; }
